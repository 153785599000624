import React, { useState, useEffect, useRef } from "react";
import { Card, Form, Spinner } from "react-bootstrap";
import "./Submitted Applications.css";
import ConfirmationModal from "../Confirmation Modal/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { BsArrowLeft } from "react-icons/bs";
import { Col, Row, Button } from "react-bootstrap";
import Heading from "../Heading/Heading";
import NavigationButtons from "../Navigation Buttons/Navigation Buttons";
import { getSubmitted_Application_API } from "../../api";
import Loader from "../Loader/Loader";

const SubmittedApplications = ({ onLogout }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const debounceTimeout = useRef(null);
  const [currentStatusFilter, setCurrentStatusFilter] = useState("All");

  const [showNoDataMessage, setShowNoDataMessage] = useState(false);

  // const [dataPerPageButton, setDataPerPageButton] = useState("10");
  // const [currentPage, setCurrentPage] = useState(1);
  // const [searchQuery, setSearchQuery] = useState("");

  // Initialize state from localStorage or default values
  const [dataPerPageButton, setDataPerPageButton] = useState(() => {
    const storedValue = localStorage.getItem(
      "submittedApplication_dataPerPageButton"
    );
    return storedValue ? JSON.parse(storedValue) : "10";
  });

  const [currentPage, setCurrentPage] = useState(() => {
    const storedValue = localStorage.getItem(
      "submittedApplication_currentPage"
    );
    return storedValue ? JSON.parse(storedValue) : 1;
  });

  const [searchQuery, setSearchQuery] = useState(() => {
    const storedValue = localStorage.getItem(
      "submittedApplication_searchQuery"
    );
    return storedValue || "";
  });

  // Update localStorage whenever state changes
  useEffect(() => {
    localStorage.setItem(
      "submittedApplication_dataPerPageButton",
      JSON.stringify(dataPerPageButton)
    );
  }, [dataPerPageButton]);

  useEffect(() => {
    localStorage.setItem(
      "submittedApplication_currentPage",
      JSON.stringify(currentPage)
    );
  }, [currentPage]);

  useEffect(() => {
    localStorage.setItem("submittedApplication_searchQuery", searchQuery);
  }, [searchQuery]);

  const [logoutClicked, setLogoutClicked] = useState(false);
  const [ExtraData, setExtraData] = useState();

  const [PointerID, setPointerID] = useState(null);

  const [showConfirmationModalForLogout, setShowConfirmationModalForLogout] =
    useState(false);

  const [showConfirmationBackModal, setShowConfirmationBackModal] =
    useState(false);
  const [backButtonClicked, setBackButtonClicked] = useState(false);

  const [currentPageData, setCurrentPageData] = useState([]);
  const getSubmittedApplicationsFunction = async (data) => {
    try {
      setLoading(true);

      const response = await getSubmitted_Application_API(data);

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data &&
        response?.data?.response?.data?.page_data
      ) {
        setExtraData(response?.data?.response?.data);
        setCurrentPageData(response?.data?.response?.data?.page_data);
      } else {
        setLoading(false);

        console.error(
          "Error fetching occupations data:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);

      console.error("Error fetching occupations data:", error);
    }
  };

  const fetchSubmittedApplications = () => {
    const formDataClearApplicationsRecods = new FormData();
    formDataClearApplicationsRecods.append("search_input", searchQuery);
    formDataClearApplicationsRecods.append("search_flag", "");
    formDataClearApplicationsRecods.append("itemsPerPage", dataPerPageButton);
    formDataClearApplicationsRecods.append("page", currentPage);

    getSubmittedApplicationsFunction(formDataClearApplicationsRecods);
  };
  useEffect(() => {
    fetchSubmittedApplications();
  }, []);

  const handleConfirmationBackNo = () => {
    setShowConfirmationBackModal(false);
  };
  const handleConfirmationBackYes = () => {
    navigate("/user/dashboard");
    setShowConfirmationBackModal(false);
    toast.success("Navigated to Dashboard.");
  };

  const handleBack = () => {
    // setBackButtonClicked(true);
    // setShowConfirmationBackModal(true);

    navigate("/user/dashboard");


  };

  const handleUpdatePersonalDetails = () => {
    navigate("/user/update_profile");
  };

  const handleLogout = () => {
    setShowConfirmationModalForLogout(true);
    setLogoutClicked(true);
  };
  const handleConfirmationLogoutYes = () => {
    localStorage.clear();
    onLogout();
    if (onLogout) {
      navigate("/");
    }
    navigate("/");
    toast.success("Logged out successfully!");
  };

  const handleConfirmationLogoutNo = () => {
    setShowConfirmationModalForLogout(false);
    setLogoutClicked(false);
  };

  // const handleViewApplication = (pointer_id, current_status , applicant_name,view_page_navigator) => {

  //   localStorage.setItem('active_candidate_name', applicant_name);

  //   const pointerData = {
  //     pointer_id,
  //     portal_refrance_no: currentPageData.find(
  //       (item) => item?.id === pointer_id
  //     )?.prn,
  //   };

  //   setPointerID(pointer_id);

  //   if (current_status === "Completed" || ( view_page_navigator === false )) {
  //     navigate("/user/final_page", {
  //       state: { pointerData },
  //     });

  //   } else {
  //     navigate("/user/view_application", {
  //       state: { pointerData },
  //     });
  //   }

  //   // navigate("/user/final_page", {
  //   //   state: { pointerData },
  //   // });

  //   // navigate("/user/view_application", {
  //   //   state: { pointerData },
  //   // });

  // };

  const handleViewApplication = (item) => {
    localStorage.setItem("active_candidate_name", item?.applicant_name);

    const pointerData = {
      pointer_id: item?.id,
      portal_refrance_no: currentPageData.find((it) => it?.id === item?.id)
        ?.prn,
    };

    setPointerID(item?.id);

    if (
      item?.current_status === "Completed" ||
      item?.view_page_navigator === false
    ) {
      navigate("/user/final_page", {
        state: { pointerData },
      });
    } else {
      navigate("/user/view_application", {
        state: { pointerData },
      });
    }
  };

  useEffect(() => {
    const currentButton = document.querySelector(".paginate_button_current");
    if (currentButton) {
      currentButton.disabled = true;
    }
  }, []);
















  const totalPages = Math.ceil(ExtraData?.totalRows / dataPerPageButton);
  const visiblePages = 4;

  const [startPage, setStartPage] = useState(() => {
    const savedStartPage = localStorage.getItem(
      "startPage_agent_submittedApplications"
    );
    return savedStartPage ? Number(savedStartPage) : 1;
  });

  useEffect(() => {
    fetchPageData(currentPage);
    // Update local storage when currentPage or startPage changes
    // localStorage.setItem('submittedApplication_currentPage', currentPage);
    localStorage.setItem("startPage_agent_submittedApplications", startPage);
  }, [currentPage, startPage]);

  const fetchPageData = (pageNumber) => {
    const formDataApplicationsRecords = new FormData();
    formDataApplicationsRecords.append("search_input", searchQuery);
    formDataApplicationsRecords.append("itemsPerPage", dataPerPageButton);
    formDataApplicationsRecords.append("page", pageNumber);

    // Your data fetching function
    getSubmittedApplicationsFunction(formDataApplicationsRecords);
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleFirst = () => {
    setCurrentPage(1);
    setStartPage(1);
  };

  const handleLast = () => {
    const lastPage = totalPages;
    setCurrentPage(lastPage);
    const newStartPage = Math.max(1, lastPage - visiblePages + 1);
    setStartPage(newStartPage);
  };

  const handlePrev = () => {
    if (startPage > 1) {
      const newStartPage = Math.max(1, startPage - visiblePages);
      setStartPage(newStartPage);
      setCurrentPage(newStartPage);
    }
  };

  const handleNext = () => {
    if (startPage + visiblePages <= totalPages) {
      const newStartPage = startPage + visiblePages;
      setStartPage(newStartPage);
      setCurrentPage(newStartPage);
    }
  };

  // const handlePrevious = () => {
  //   // Only decrement the current page if it's greater than 1
  //   if (currentPage > 1) {
  //     setCurrentPage(currentPage - 1);
  //   }

  //   // Determine the previous page number, ensuring it doesn't go below 0
  //   const currentPreviousPageNo = currentPage > 1 ? currentPage - 1 : 0;

  //   // Prepare form data
  //   const formDataApplicationsRecords = new FormData();
  //   formDataApplicationsRecords.append("search_input", searchQuery);
  //   formDataApplicationsRecords.append("search_flag", "");
  //   formDataApplicationsRecords.append("itemsPerPage", dataPerPageButton);
  //   formDataApplicationsRecords.append("page", currentPreviousPageNo);

  //   // Call the function to get the application manager data
  //   getSubmittedApplicationsFunction(formDataApplicationsRecords);
  // };

  // const handleNext = () => {
  //   setCurrentPage(currentPage + 1);

  //   const currentNextPageNo = currentPage + 1;

  //   // Prepare form data
  //   const formDataApplicationsRecords = new FormData();
  //   formDataApplicationsRecords.append("search_input", searchQuery);
  //   formDataApplicationsRecords.append("search_flag", "");
  //   formDataApplicationsRecords.append("itemsPerPage", dataPerPageButton);
  //   formDataApplicationsRecords.append("page", currentNextPageNo);

  //   getSubmittedApplicationsFunction(formDataApplicationsRecords);
  // };

  const handleSearch = (event) => {

    setStartPage(1);
    localStorage.setItem("startPage_agent_submittedApplications", 1);


    setSearchQuery(event.target.value);
    const searchInputByUser = event.target.value;

    // Prepare form data
    const formDataApplicationsRecords = new FormData();
    formDataApplicationsRecords.append("search_input", searchInputByUser);
    formDataApplicationsRecords.append("search_flag", "");
    formDataApplicationsRecords.append("itemsPerPage", 10);
    formDataApplicationsRecords.append("page", 1);

    // Debounce the API call
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      getSubmittedApplicationsFunction(formDataApplicationsRecords);
    }, 1000);
  };

  useEffect(() => {
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, []);

  const handleDataPerPageChange = (event) => {
    setDataPerPageButton(event.target.value);
    const rowLength = event.target.value;
    const formDataApplicationsRecods = new FormData();
    formDataApplicationsRecods.append("search_input", searchQuery);
    formDataApplicationsRecods.append("search_flag", "");

    // formDataApplicationsRecods.append("itemsPerPage", rowLength);
    // formDataApplicationsRecods.append("page", currentPage);

    formDataApplicationsRecods.append("itemsPerPage", rowLength);
    formDataApplicationsRecods.append("page", 1);

    localStorage.setItem("submittedApplication_currentPage", 1);
    setCurrentPage(1);

    localStorage.setItem("startPage_agent_submittedApplications", 1);
    setStartPage(1);

    getSubmittedApplicationsFunction(formDataApplicationsRecods);
  };

  return (
    <>
      <Row>
        <Col>
          <Button
            className="back-button"
            variant=""
            onClick={handleBack}
            style={{
              // backgroundColor: "#f0f8ff",
              color: "#055837",
              marginLeft: "25px",
              outline: "none",
              boxShadow: "none",
            }}
          >
            <BsArrowLeft className="me-0" />
            Back to Dashboard
          </Button>
        </Col>

        <Col>
          <NavigationButtons
            handleUpdatePersonalDetails={handleUpdatePersonalDetails}
            handleLogout={handleLogout}
          />
        </Col>
        <Heading text="Submitted Applications" />
      </Row>

      {/* {loading && (
        <div className="d-flex justify-content-center mt-3">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )} */}

      {loading && <Loader />}

      <div className="incomplete-info">
        <Card className="shadow forgot-card-identification">
          <Card.Body>
            <div className="account-details">
              <div id="table_wrapper" className="dataTables_wrapper no-footer">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="dataTables_lengthSb">
                    <label>
                      <select
                        value={dataPerPageButton}
                        onChange={handleDataPerPageChange}
                        style={{ cursor: "pointer" }}
                      >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </label>
                  </div>

                  <div style={{ display: "flex", justifyContent: "right" }}>
                    {/* <div
                      className="filterButton"
                      style={{ marginRight: "10px" }}
                    >
                      <Form.Group controlId="statusSelect">
                        <Form.Select
                          value={currentStatusFilter}
                          onChange={(e) => handleFilterChange(e.target.value)}
                          style={{
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                            padding: "5px",
                            height: "40px",
                          }}
                        >
                          <option value="" disabled>
                            Filter by Current Status
                          </option>
                          <option value="All">All</option>

                          <option value="S1 - Submitted">S1 - Submitted</option>
                          <option value="S1 - Lodged">S1 - Lodged</option>
                          <option value="S1 - In Progress">
                            S1 - In Progress
                          </option>
                          <option value="S1 - Approved">S1 - Approved</option>
                          <option value="S1 - Declined">S1 - Declined</option>
                          <option value="S1 - Withdrawn">S1 - Withdrawn</option>
                          <option value="S1 - Reinstate">S1 - Reinstate</option>
                          <option value="S1 - Expired">S1 - Expired</option>
                          <option value="S1 - Archive">S1 - Archive</option>
                          <option value="S1 - Omitted">S1 - Omitted</option>

                          <option value="S2 - Start">S2 - Start</option>
                          <option value="S2 - Submitted">S2 - Submitted</option>
                          <option value="S2 - Lodged">S2 - Lodged</option>
                          <option value="S2 - In Progress">
                            S2 - In Progress
                          </option>
                          <option value="S2 - Approved">S2 - Approved</option>
                          <option value="S2 - Declined">S2 - Declined</option>
                          <option value="S2 - Withdrawn">S2 - Withdrawn</option>
                          <option value="S2 - Reinstate">S2 - Reinstate</option>

                          <option value="S3 - Start">S3 - Start</option>
                          <option value="S3 - Submitted">S3 - Submitted</option>
                          <option value="S3 - Lodged">S3 - Lodged</option>
                          <option value="S3 - In Progress">
                            S3 - In Progress
                          </option>
                          <option value="S3 - Scheduled">S3 - Scheduled</option>
                          <option value="S3 - Conducted">S3 - Conducted</option>
                          <option value="S3 - Approved">S3 - Approved</option>
                          <option value="S3 - Declined">S3 - Declined</option>
                          <option value="S3 - Withdrawn">S3 - Withdrawn</option>
                          <option value="S3 - Reintent">S3 - Reintent</option>
                          <option value="S3 - Start (R)">S3 - Start (R)</option>
                          <option value="S3 - Submitted (R)">
                            S3 - Submitted (R)
                          </option>
                          <option value="S3 - Lodged (R)">
                            S3 - Lodged (R)
                          </option>
                          <option value="S3 - In Progress (R)">
                            S3 - In Progress (R)
                          </option>
                          <option value="S3 - Scheduled (R)">
                            S3 - Scheduled (R)
                          </option>
                          <option value="S3 - Conducted (R)">
                            S3 - Conducted (R)
                          </option>
                          <option value="S3 - Approved (R)">
                            S3 - Approved (R)
                          </option>
                          <option value="S3 - Declined (R)">
                            S3 - Declined (R)
                          </option>
                          <option value="S3 - Withdrawn (R)">
                            S3 - Withdrawn (R)
                          </option>

                          <option value="S4 - Start">S4 - Start</option>
                          <option value="S4 - Submitted">S4 - Submitted</option>
                          <option value="S4 - Lodged">S4 - Lodged</option>
                          <option value="S4 - In Progress">
                            S4 - In Progress
                          </option>
                          <option value="S4 - Scheduled">S4 - Scheduled</option>
                          <option value="S4 - Conducted">S4 - Conducted</option>
                          <option value="S4 - Approved">S4 - Approved</option>
                          <option value="S4 - Declined">S4 - Declined</option>
                          <option value="S4 - Withdrawn">S4 - Withdrawn</option>

                          <option value="Closed">Closed</option>

                          <option value="Completed">Completed</option>

                          <option value="--//--">--//--</option>
                        </Form.Select>
                      </Form.Group>
                    </div> */}

                    <div
                      id="table_filter"
                      className="dataTables_filter"
                      style={{ marginLeft: "10px" }}
                    >
                      <label>
                        <i className="fa fa-search"></i>
                        <input
                          type="search"
                          placeholder="Search"
                          value={searchQuery}
                          onChange={handleSearch}
                          style={{
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                            padding: "5px",
                            height: "40px",
                          }}
                        />
                      </label>
                    </div>
                  </div>
                </div>

                {!showNoDataMessage &&
                  currentPageData?.length === 0 &&
                  !loading && (
                    <div className="no-data-message">
                      <p className="oops-message">Oops! No data to display.</p>
                    </div>
                  )}

                <div class="container-fluid">
                  <div class="row">
                    <div class="col-12">
                      <div class="table-responsive">
                        <table class="table table-striped table-hover dataTable no-footer">
                          <thead>
                            <tr>
                              <th scope="col" class="col-1">
                                PRN
                              </th>
                              <th scope="col" class="col-1_75">
                                Application No.
                              </th>
                              <th scope="col" class="col-2">
                                Applicant Name
                              </th>
                              <th scope="col" class="col-1_25">
                                D.O.B
                              </th>
                              <th scope="col" class="col-1_75">
                                Occupation
                              </th>
                              <th scope="col" class="col-1_25">
                                Date Created
                              </th>
                              <th scope="col" class="col-1_25">
                                Current Status
                              </th>
                              <th scope="col" class="col-1">
                                Action
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {currentPageData
                              .slice(0, parseInt(dataPerPageButton))
                              .map((item, index) => (
                                <tr
                                  key={index}
                                  className={index % 2 === 0 ? "even" : "odd"}
                                >
                                  <td>
                                    <strong>{item?.prn}</strong>
                                  </td>
                                  <td>
                                    <strong>
                                      {item?.applicant_no.applicant_no}
                                    </strong>
                                  </td>
                                  <td>{item?.applicant_name}</td>

                                  {/* <td>
                                    {formatDate(item?.date_of_birth) ===
                                    "NaN/NaN/NaN"
                                      ? "--//--"
                                      : formatDate(item?.date_of_birth)}
                                  </td> */}

                                  <td>{item?.dob}</td>

                                  <td>{item?.occupation}</td>
                                  <td>{item?.created_date}</td>
                                  <td>{item?.current_status}</td>

                                  <td>
                                    <div className="btn-container">
                                      <button
                                        className="btn btn-sm edit"
                                        style={{
                                          backgroundColor: "#055837",
                                          color: "#ffcc01",
                                          marginRight: "8px",
                                          transition:
                                            "background-color 0.2s, color 0.2s",
                                        }}
                                        // onClick={() =>
                                        //   handleViewApplication(
                                        //     item?.id,
                                        //     item?.current_status,
                                        //     item?.applicant_name,
                                        //     item?.view_page_navigator,
                                        //   )
                                        // }

                                        onClick={() =>
                                          handleViewApplication(item)
                                        }
                                      >
                                        <i className="bi bi-eye"></i>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <hr className="mb-3 thick-hr" />

                {/* 

                <div className="pagination-container">
                  <div className="dataTables_info">
                    Showing {ExtraData?.currentPage} to{" "}
                    {ExtraData?.itemsPerPage} of {ExtraData?.totalRows} entries
                  </div>

                  <div className="pagination-buttons">
                    <button
                      className={`paginate_button ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                      onClick={handlePrevious}
                      disabled={currentPage === 1}
                      style={
                        currentPage === 1
                          ? {
                              pointerEvents: "none",
                              cursor: "not-allowed",
                            }
                          : {}
                      }
                    >
                      Previous
                    </button>

                    <button className="paginate_button">{currentPage}</button>

                    <button
                      className={`paginate_button ${
                        ExtraData?.next_disabled === true ? "disabled" : ""
                      }`}
                      onClick={handleNext}
                      disabled={ExtraData?.next_disabled === true}
                      style={
                        ExtraData?.next_disabled === true
                          ? {
                              pointerEvents: "none",
                              cursor: "not-allowed",
                            }
                          : {}
                      }
                    >
                      Next
                    </button>
                  </div>
                </div>


*/}

                <div className="pagination-container">
                  <div className="dataTables_info">
                    Showing {ExtraData?.currentPage} to{" "}
                    {ExtraData?.itemsPerPage} of {ExtraData?.totalRows} entries
                  </div>

                  {/* <span
                    style={{
                      background:
                        "linear-gradient(to bottom right, #fff9c4, #fff3e0)",
                      color: "#055837",
                      padding: "8px 12px",
                      borderRadius: "5px",
                      // boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                      // fontWeight: 'bold',
                      // fontSize: '16px',
                      display: "inline-block",
                      cursor: "default",
                    }}
                  >
                    Active Page : {currentPage}
                  </span> */}

                  {/* 

<div className="pagination-buttons">

<button
  className={`paginate_button ${currentPage === 1 ? "disabled" : ""}`}
  onClick={handleFirst}
  disabled={currentPage === 1}
  style={currentPage === 1 ? { pointerEvents: "none", cursor: "not-allowed" } : {}}
>
  First
</button>


    <div style={{ display: 'flex', alignItems: 'center' }}>
      <button
        className={`paginate_button ${startPage === 1 ? "disabled" : ""}`}
        onClick={handlePrev}
        disabled={startPage === 1}
        style={startPage === 1 ? { pointerEvents: "none", cursor: "not-allowed" } : {}}
      >
        Previous
      </button>

      <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', flex: 1 }}>
        {Array.from({ length: visiblePages }, (_, index) => {
          const pageNumber = startPage + index;
          if (pageNumber > totalPages) return null; // Do not render if out of bounds

          const isActive = currentPage === pageNumber;

          return (
            <button
              key={pageNumber}
              className={`paginate_button ${isActive ? "active" : ""}`}
              style={{
                display: 'inline-block',
                margin: '0 4px',
                backgroundColor: isActive ? "black" : "transparent",
                color: isActive ? "white" : "black",
              }}
              onClick={() => handlePageClick(pageNumber)}
            >
              {pageNumber}
            </button>
          );
        })}
      </div>

      <button
        className={`paginate_button ${(startPage + visiblePages - 1 >= totalPages) ? "disabled" : ""}`}
        onClick={handleNext}
        disabled={startPage + visiblePages - 1 >= totalPages}
        style={startPage + visiblePages - 1 >= totalPages ? { pointerEvents: "none", cursor: "not-allowed" } : {}}
      >
        Next
      </button>
    </div>

    <button
      className={`paginate_button ${currentPage === totalPages ? "disabled" : ""}`}
      onClick={handleLast}
      disabled={currentPage === totalPages}
      style={currentPage === totalPages ? { pointerEvents: "none", cursor: "not-allowed" } : {}}
    >
      Last
    </button>
  </div> */}

                  <div className="pagination-buttons">
                    <button
                      className={`paginate_button ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                      onClick={handleFirst}
                      disabled={currentPage === 1}
                    >
                      First
                    </button>

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <button
                        className={`paginate_button ${
                          startPage === 1 ? "disabled" : ""
                        }`}
                        onClick={handlePrev}
                        disabled={startPage === 1}
                      >
                        Previous
                      </button>

                      <div
                        style={{
                          overflowX: "auto",
                          whiteSpace: "nowrap",
                          flex: 1,
                        }}
                      >
                        {Array.from({ length: visiblePages }, (_, index) => {
                          const pageNumber = startPage + index;
                          if (pageNumber > totalPages) return null;

                          const isActive = currentPage === pageNumber;

                          return (
                            <button
                              key={pageNumber}
                              className={`paginate_button ${
                                isActive ? "active" : ""
                              }`}
                              style={{
                                display: "inline-block",
                                margin: "0 4px",
                                backgroundColor: isActive
                                  ? "black"
                                  : "transparent",
                                color: isActive ? "white" : "black",
                              }}
                              onClick={() => handlePageClick(pageNumber)}
                            >
                              {pageNumber}
                            </button>
                          );
                        })}
                      </div>

                      <button
                        className={`paginate_button ${
                          startPage + visiblePages - 1 >= totalPages
                            ? "disabled"
                            : ""
                        }`}
                        onClick={handleNext}
                        disabled={startPage + visiblePages - 1 >= totalPages}
                      >
                        Next
                      </button>
                    </div>

                    <button
                      className={`paginate_button ${
                        currentPage === totalPages ? "disabled" : ""
                      }`}
                      onClick={handleLast}
                      disabled={currentPage === totalPages}
                    >
                      Last
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>

          {backButtonClicked && (
            <ConfirmationModal
              show={showConfirmationBackModal}
              onHide={handleConfirmationBackNo}
              onConfirm={handleConfirmationBackYes}
              title="Confirm Navigation"
              message="Are you sure you want to go back to the Dashboard page?"
            />
          )}

          {logoutClicked && (
            <ConfirmationModal
              show={showConfirmationModalForLogout}
              onHide={handleConfirmationLogoutNo}
              onConfirm={handleConfirmationLogoutYes}
              title="Confirm Exit"
              message="Are you sure you want to log out?"
            />
          )}
        </Card>
      </div>
    </>
  );
};

export default SubmittedApplications;
