import React, { useState, useEffect, useRef } from 'react'
import { Col, Row } from 'react-bootstrap'
import File_Uploader_Box from './File_Uploader_Box'
import { useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import { uploadTheDocument_stage_2_R_api, getDocumentBasedOnReqdID_stage_2_R_api, delete_Document_Stage_2_R_api } from '../../../../api';
import ConfirmationModal from '../../../Confirmation Modal/ConfirmationModal';


export default function Additional_Supporting_Evidence({
    heading_text,
    required_document_id,
    setLoading
}) {
    const location = useLocation();
    const pointerData = location.state?.pointerData;
    const [confirm_delete_popup, setConfirm_delete_popup] = useState(false);
    const [selectedFileName, setSelectedFileName] = useState("");
    const [currentDocumentId, setCurrentDocumentId] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const fileInputRef = useRef(null);
    const [docs, setDocs] = useState([]);

    
    

    // Function to handle file selection
    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        if (file) {
        const fileType = file.name.split(".").pop(); // Get the file extension
        setSelectedFile(file);
        // setSelectedFileName(file.name);
        // Check if the selected file type is allowed
        // if (allowedTypes.includes(fileType)) {
        //     setSelectedFile(file);
        //     setSelectedFileName(file.name);
        // } else {
        //     // Show toast notification if the file type is not allowed
        //     toast.error(`Please select a file of type: ${allowedTypes.join(", ")}`);
        //     event.target.value = ""; // Clear the input field
        //     setSelectedFile(null); // Reset the state
        //     setSelectedFileName(""); // Reset the filename state
        // }
        }
    };


    const clearTheInputs = () => {
        setProgress(0); // Reset progress after success
        getDocumentBasedOnReqdID();
        setSelectedFile(null); // Reset file state
        setSelectedFileName(''); // Reset filename state
        if (fileInputRef.current) {
            fileInputRef.current.value = ''; // Clear the file input field
        }

    }

    const uploadTheDocument = async () => {
        // console.log(selectedFile);
        // return;

        if(required_document_id === "60"  && selectedFileName === ""){
            toast.error(`Fill the Document Name.`);
            return;
        }
        else if(selectedFile === null){
            toast.error(`Select a Document.`);
            return;
        }


        console.log("pointer_id", pointerData?.pointer_id);
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("file_name", selectedFileName);
        formData.append("required_document_id", required_document_id); // 59 = ass and 60 = emp
        
        // console.log(formData);
        try {
            const result = await uploadTheDocument_stage_2_R_api(
              pointerData?.pointer_id,
              formData,
              (progressEvent) => {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                setProgress(percentCompleted);
              }
            );
      
            if (result.status === 200 && result?.data?.response?.response == true) {
              toast.success(result?.data?.response?.success_msg);
              clearTheInputs();
            } else {
              toast.error(result?.data?.response?.error_msg);
              setProgress(0); // Reset progress after success
            }
          } catch (error) {
            console.error(error);
            toast.error('An error occurred while uploading the file.');
            setProgress(0); // Reset progress on error
          }

        // console.log(result);


    }


    const getDocumentBasedOnReqdID = async () => {
        const formData = new FormData();
        formData.append("pointer_id", pointerData?.pointer_id);
        formData.append("reqd_document_id", required_document_id);

        try{
            const result = await getDocumentBasedOnReqdID_stage_2_R_api(formData);
            setDocs(result?.data?.response?.data);
        }
        catch (error) {
            console.error(error);
            toast.error('An error occurred while fetching Files.');
          }

    }


    const delete_Document_Stage_2_R = async () => {
        setLoading(true);
        try{
            const result = await delete_Document_Stage_2_R_api(currentDocumentId);

            if (result.status === 200 && result?.data?.response?.response == true) {
                toast.success(result?.data?.response?.success_msg);
                getDocumentBasedOnReqdID();
              } else {
                toast.error(result?.data?.response?.error_msg);
               
              }
              setLoading(false);
        }
        catch (error) {
            console.error(error);
            toast.error('An error occurred while fetching deleting.');
            setLoading(false);
          }
    }

    useEffect(() => {
        getDocumentBasedOnReqdID();
    }, []);

    return (
        <>

            <div className="documentary-upload-docs-emp-container-contact-details">
                <div className="shadow documentary-upload-docs-emp-card-contact-details card">
                    <div className="card-header text-center" style={{ fontSize: 20, color: 'rgb(5, 88, 55)', cursor: 'default' }}>
                        <b>{heading_text}</b>
                    </div>
                    <form>
                        {required_document_id === "60" ? (
                        <div className="row mb-3 gx-1" style={{ margin: '15px 5px 5px' }}>
                            <div className="col-md-5">
                                <label>Document Name</label>
                                <input type='text' className='form-control' style={{
                                    height: '38px'
                                }}
                                onChange={(e) => {
                                    setSelectedFileName(e.target.value);
                                }}
                                value={selectedFileName}
                                />
                            </div>
                            <div className="col-md-6">
                                <label>Upload File</label>
                                <input type='file' className='form-control' style={{
                                    height: '38px'
                                }}
                                ref={fileInputRef}
                                onChange={handleFileSelect}
                                accept='.pdf,.xlx,.xlsx,.jpg,.png'
                                />
                                <span className='text-danger'>Only : .pdf,.xlx,.xlsx,.jpg,.png</span>
                            </div>
                            <div className="col-md-1" style={{
                                marginTop: "29px",
                                paddingRight: "6px"
                            }}>
                                <button type='button' className='btn btn_green_yellow btn-sm' style={{
                                    float: "right"
                                }}
                                onClick={uploadTheDocument}
                                >
                                    <i className='fas fa-upload'></i>
                                </button>
                            </div>

                            <div className='col-md-12'>
                                
                                {progress > 0 && (
                                    <div className="progress mt-3">
                                    <div
                                        className="progress-bar"
                                        role="progressbar"
                                        style={{ width: `${progress}%` }}
                                        aria-valuenow={progress}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                    >
                                        {progress}%
                                    </div>
                                    </div>
                                )}



                            </div>
                        </div>
                        ) : (
                            <div className="row mb-3 gx-1" style={{ margin: '15px 5px 5px' }}>
                                <div className="col-md-11">
                                    <label>Upload TRA Payment Receipt</label>
                                    <input type='file' className='form-control' style={{
                                        height: '38px'
                                    }}
                                    ref={fileInputRef}
                                    onChange={handleFileSelect}
                                    disabled = {docs.length > 0 ? true : false }
                                    accept='.pdf'
                                    />
                                    <span className='text-danger'>Only : .pdf</span>
                                </div>
                                <div className="col-md-1" style={{
                                    marginTop: "29px",
                                    paddingRight: "6px"
                                }}>
                                    <button type='button' className='btn btn_green_yellow btn-sm' style={{
                                        float: "right"
                                    }}
                                    disabled = {docs.length > 0 ? true : false }
                                    onClick={uploadTheDocument}
                                    >
                                        <i className='fas fa-upload'></i>
                                    </button>
                                </div>

                                <div className='col-md-12'>
                                    
                                    {progress > 0 && (
                                        <div className="progress mt-3">
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{ width: `${progress}%` }}
                                            aria-valuenow={progress}
                                            aria-valuemin={0}
                                            aria-valuemax={100}
                                        >
                                            {progress}%
                                        </div>
                                        </div>
                                    )}



                                </div>
                            </div>
                        )}
                        
                    </form>
                    

                    <File_Uploader_Box 
                    docs = {docs}
                    setCurrentDocumentId = {setCurrentDocumentId}
                    setConfirm_delete_popup = {setConfirm_delete_popup}
                    />


                    <ConfirmationModal
                    show = {confirm_delete_popup}
                    onHide  = {() => {
                        setConfirm_delete_popup(false);
                    }}
                    onConfirm = {() => {
                        setConfirm_delete_popup(false);
                        delete_Document_Stage_2_R();
                    }} 
                    title = "System Alert"
                    message = "Are you sure to delete document ?"
                    />

                </div>
            </div>

        </>
    )
}
