import React, { useState, useEffect } from "react";
import "./Forms.css";
import { Modal } from "react-bootstrap";
import { BsArrowLeft } from "react-icons/bs";
import { Col, Row, Button, Spinner } from "react-bootstrap";
import Heading from "../Heading/Heading";
import NavigationButtons from "../Navigation Buttons/Navigation Buttons";
import ConfirmationModal from "../Confirmation Modal/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Card } from "react-bootstrap";

import { getForms_PDF_URL__API } from "../../api";
import Loader from "../Loader/Loader";

const Forms = ({ onLogout }) => {
  const userID = localStorage.getItem("userId");
  
  
  const [showModal, setShowModal] = useState(true);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [logoutClicked, setLogoutClicked] = useState(false);
  const [showConfirmationModalForLogout, setShowConfirmationModalForLogout] = useState(false);
  const [showConfirmationBackModal, setShowConfirmationBackModal] = useState(false);
  const [backButtonClicked, setBackButtonClicked] = useState(false);

  const [forms, setForms] = useState([]);

  const handleConfirmationBackNo = () => {
    setShowConfirmationBackModal(false);
  };

  const handleConfirmationBackYes = () => {
    navigate("/user/dashboard");
    setShowConfirmationBackModal(false);
    toast.success("Navigated to Dashboard.");
  };

  const handleBack = () => {
    // setBackButtonClicked(true);
    // setShowConfirmationBackModal(true);

    navigate("/user/dashboard");


  };

  const handleUpdatePersonalDetails = () => {
    navigate("/user/update_profile");
  };

  const handleLogout = () => {
    setShowConfirmationModalForLogout(true);
    setLogoutClicked(true);
  };

  const handleConfirmationLogoutYes = () => {
    localStorage.clear();
    onLogout();
    navigate("/");
    toast.success("Logged out successfully!");
  };

  const handleConfirmationLogoutNo = () => {
    setShowConfirmationModalForLogout(false);
    setLogoutClicked(false);
  };

  useEffect(() => {
    handleShowModal();
  }, []);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleConfirm = () => {
    handleCloseModal();
  };

  const handleDownload = (link, name) => {
    if (link) {
      const downloadLink = document.createElement("a");
      downloadLink.href = link;
      downloadLink.setAttribute("target", "_blank");
      downloadLink.click();
      toast.success(`Downloaded Opened PDF of ${name}!`);
    } else {
      toast.error(`Failed to download ${name}. Link not available.`);
    }
  };

  const getFormsPDFDetailsFunction = async () => {
    const formData = new FormData();
    formData.append("user_id", userID);

    try {
      setLoading(true);

      const response = await getForms_PDF_URL__API(formData);

      setLoading(false);

      if (response?.data?.response?.response === true) {
        const formDetails = response?.data?.response?.data || [];
        setForms(formDetails);
      } else {
        toast.error(response?.data?.response?.error_msg || "Failed to get Forms to download");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Failed to get Forms to download");
    }
  };

  useEffect(() => {
    if (userID && !showModal) {
      getFormsPDFDetailsFunction();
    }
  }, [userID, showModal]);

  return (
    <>
      <Row>
        <Col>
          <Button
            className="back-button"
            variant=""
            onClick={handleBack}
            style={{ color: "#055837", marginLeft: "25px", outline: "none", boxShadow: "none" }}
          >
            <BsArrowLeft className="me-0" />
            Back to Dashboard
          </Button>
        </Col>

        <Col>
          <NavigationButtons
            handleUpdatePersonalDetails={handleUpdatePersonalDetails}
            handleLogout={handleLogout}
          />
        </Col>
        <Heading text="Forms" />
      </Row>

      <div className="forgot-container-identification">
        <Card className="shadow forgot-card-identification">
          <Card.Body>



            {/* {loading && (
              <div className="d-flex justify-content-center mt-2">
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            )} */}



            
{loading && (
 <Loader />
      )}



            <div className="account-details">
              <table className="table">
                <tbody style={{ textAlign: "center" }}>
                  {forms.map((form, index) => (
                    <tr key={index}>
                      <td className="col-7">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            textAlign: "center",
                          }}
                        >
                          <b>{form?.name}</b>
                        </div>
                      </td>
                      <td className="col-5">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <a
                            className="btn_green_yellow btn"
                            onClick={(e) => {
                              e.preventDefault();
                              handleDownload(form?.download_link, form?.name);
                            }}
                            style={{
                              marginLeft: "15px",
                              padding: "5px 10px",
                              backgroundColor: "#055837",
                              color: "#ffcc01",
                              borderRadius: "5px",
                              textDecoration: "none",
                            }}
                            onMouseEnter={(e) => {
                              e.target.style.backgroundColor = "#ffcc01";
                              e.target.style.color = "#055837";
                            }}
                            onMouseLeave={(e) => {
                              e.target.style.backgroundColor = "#055837";
                              e.target.style.color = "#ffcc01";
                            }}
                          >
                            <i className="bi bi-download"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Card.Body>
        </Card>

        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header>
            <Modal.Title
              className="text-center"
              style={{
                fontSize: "18px",
                fontFamily: "Arial, sans-serif",
                color: "#055837",
              }}
            >
              {"Note :-"}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body
            className="text-center"
            style={{ fontSize: "16px", fontFamily: "Arial, sans-serif" }}
          >
            {
              "These forms are there for your convenience in case they are required after the application lodgement. We encourage you to download & use the forms generated while creating a new application."
            }
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button
              variant="light"
              onClick={handleConfirm}
              className="w-50"
              style={{
                maxWidth: "80px",
                backgroundColor: "#ffcc01",
                color: "#055837",
                transition: "transform 0.2s",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "#055837";
                e.target.style.color = "#ffcc01";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#ffcc01";
                e.target.style.color = "#055837";
              }}
            >
              OK
            </Button>
          </Modal.Footer>
        </Modal>

        {backButtonClicked && (
          <ConfirmationModal
            show={showConfirmationBackModal}
            onHide={handleConfirmationBackNo}
            onConfirm={handleConfirmationBackYes}
            title="Confirm Navigation"
            message="Are you sure you want to go back to the Dashboard page?"
          />
        )}

        {logoutClicked && (
          <ConfirmationModal
            show={showConfirmationModalForLogout}
            onHide={handleConfirmationLogoutNo}
            onConfirm={handleConfirmationLogoutYes}
            title="Confirm Exit"
            message="Are you sure you want to log out?"
          />
        )}
      </div>
    </>
  );
};

export default Forms;
