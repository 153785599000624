import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "./Employment Verification Table Modal.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EmploymentVerificationTableModal = ({
  show,
  onHide,
  pointerData,
  getQualificationVerificationValue,
  getEmailVerificationValue,
  ActiveStageByClick,
}) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    let data = [];
    if (ActiveStageByClick === "stage_1") {
      data = getQualificationVerificationValue.length
        ? getQualificationVerificationValue
        : [];
    } else if (ActiveStageByClick === "stage_2") {
      data = getEmailVerificationValue.length ? getEmailVerificationValue : [];
    }
    setTableData(data);
  }, [
    ActiveStageByClick,
    getQualificationVerificationValue,
    getEmailVerificationValue,
  ]);

  const modalTitle =
    ActiveStageByClick === "stage_1"
      ? "Qualification Verification Details"
      : "Employment Verification Details";

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        centered
        dialogClassName="custom-modal-spe"
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="text-center"
            style={{
              fontSize: "18px",
              fontFamily: "Arial, sans-serif",
              color: "#055837",
            }}
          >
            {modalTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {ActiveStageByClick === "stage_2" && (
            <div className="empv-container-contact-details">
              <table className="table" style={{ marginTop: "0px" }}>
                <thead>
                  <tr className="table-header">
                    <th scope="col" style={{ width: "5%", color: "white" }}>
                      Sr.No.
                    </th>
                    <th scope="col" style={{ width: "30%", color: "white" }}>
                      Referee Name
                    </th>
                    <th scope="col" style={{ width: "35%", color: "white" }}>
                      Referee Email
                    </th>
                    <th scope="col" style={{ width: "25%", color: "white" }}>
                      Company/Organisation
                    </th>
                    <th scope="col" style={{ width: "10%", color: "white" }}>
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((item, index) => (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>{item?.referee_name}</td>
                      <td>{item?.referee_email}</td>
                      <td>{item?.company_organisation_name}</td>
                      <td>
                        <span
                          className={
                            item?.is_verification_done === "Pending"
                              ? "pending-status-button"
                              : "verified-status-button"
                          }
                        >
                          {item?.is_verification_done}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {ActiveStageByClick === "stage_1" && (
            <div className="empv-container-contact-details">
              <table className="table" style={{ marginTop: "0px" }}>
                <thead>
                  <tr className="table-header">
                    <th scope="col" style={{ width: "10%", color: "white" }}>
                      Sr.No.
                    </th>
                    <th scope="col" style={{ width: "50%", color: "white" }}>
                      Email
                    </th>
                    <th scope="col" style={{ width: "10%", color: "white" }}>
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((item, index) => (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>{item?.verification_email_id}</td>
                      <td>
                        <span
                          className={
                            item?.is_verification_done === "Pending"
                              ? "pending-status-button"
                              : "verified-status-button"
                          }
                        >
                          {item?.is_verification_done}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EmploymentVerificationTableModal;
