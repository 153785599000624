import React, { useState, useEffect } from "react";
import { Card, Form, Spinner } from "react-bootstrap";
import "./Education & Employment.css";
import ConfirmationModal from "../../../Confirmation Modal/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  createEducationAndEmployment_stage1_API,
  getEducationAndEmployment_stage1_API,
} from "../../../../api";
import { useLocation } from "react-router-dom";
import Loader from "../../../Loader/Loader";

const EducationAndEmployment = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const pointerData = location.state?.pointerData;

  const [loading, setLoading] = useState(false);

  const [heighestCompletedSchoolLevel, setHeighestCompletedSchoolLevel] =
    useState("");
  const [
    stillEnrolledInSecondaryEducation,
    setstillEnrolledInSecondaryEducation,
  ] = useState("");
  const [
    successfullyCompletredAnyQualifications,
    setSuccessfullyCompletredAnyQualifications,
  ] = useState("");
  const [chooseApplicableQualification, setChooseApplicableQualification] =
    useState("");

  const [currentEmploymentStatus, setCurrentEmploymentStatus] = useState("");
  const [mainReasonForThisSkill, setMainReasonForThisSkill] = useState("");

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showConfirmationBackModal, setShowConfirmationBackModal] =
    useState(false);
  const [backButtonClicked, setBackButtonClicked] = useState(false);
  const handleConfirmationBackNo = () => {
    setShowConfirmationBackModal(false);
  };
  const handleConfirmationBackYes = () => {
    navigate("/user/create_new_application/stage_1/usi_and_avetmiss", {
      state: { pointerData },
    });
    setShowConfirmationBackModal(false);
    toast.success("Navigated to USI & Avetmiss form page.");
  };

  const handleBack = () => {
    // setBackButtonClicked(true);
    // setShowConfirmationBackModal(true);

    navigate("/user/create_new_application/stage_1/usi_and_avetmiss", {
      state: { pointerData },
    });
    toast.success("Navigated to USI & Avetmiss form page.");
  };

  const handleConfirmationNo = () => {
    setShowConfirmationModal(false);
  };

  const handleConfirmationYes = async () => {
    // navigate("/user/dashboard");
    // setShowConfirmationModal(false);
    //  toast.success("Education & Employment Details saved successfully.");

    // Construct form data object
    const formData = new FormData();
    formData.append("pointer_id", pointerData?.pointer_id);
    formData.append(
      "highest_completed_school_level",
      heighestCompletedSchoolLevel
    );
    formData.append(
      "still_enrolled_in_secondary_or_senior_secondary_education",
      stillEnrolledInSecondaryEducation
    );
    formData.append(
      "completed_any_qualifications",
      successfullyCompletredAnyQualifications
    );
    formData.append(
      "applicable_qualifications",
      successfullyCompletredAnyQualifications === "yes"
        ? chooseApplicableQualification
        : ""
    );
    formData.append("current_employment_status", currentEmploymentStatus);
    formData.append(
      "reason_for_undertaking_this_skills_assessment",
      mainReasonForThisSkill
    );
    formData.append("other_reason_for_undertaking", "");
    formData.append("status", "");

    // API call to save Education & Employment details
    try {
      setLoading(true);

      const response = await createEducationAndEmployment_stage1_API(formData);
      setLoading(false);

      if (response?.data?.response?.response === true) {
        navigate("/user/dashboard");
        setShowConfirmationModal(false);
        toast.success("Education & Employment Details saved successfully.");
      } else {
        // If API call fails, display error message
        toast.error(
          response?.data?.response?.error_msg ||
            "Failed to save Education & Employment Details"
        );
      }
    } catch (error) {
      setLoading(false);

      console.error("Error saving Education & Employment Details:", error);
      toast.error("Failed to save Education & Employment Details");
    }
  };

  const handleNext = async () => {
    // event.preventDefault();

    if (heighestCompletedSchoolLevel.trim() === "") {
      toast.error("Please select your highest COMPLETED school level");
      document.getElementById("heighestCompletedSchoolLevelSelect").focus();
      return;
    }

    if (stillEnrolledInSecondaryEducation.trim() === "") {
      toast.error(
        "Please indicate if you are still enrolled in secondary or senior secondary education"
      );
      document.getElementById("stillEnrolledInSecondaryEducationYes").focus();
      return;
    }

    if (successfullyCompletredAnyQualifications.trim() === "") {
      toast.error(
        "Please indicate if you have successfully completed any qualifications"
      );
      document
        .getElementById("successfullyCompletredAnyQualificationsSelect")
        .focus();
      return;
    }

    if (successfullyCompletredAnyQualifications === "yes") {
      if (chooseApplicableQualification.trim() === "") {
        toast.error("Please select applicable qualifications");
        document.getElementById("chooseApplicableQualificationSelect").focus();
        return;
      }
    }

    if (currentEmploymentStatus.trim() === "") {
      toast.error("Please select your current employment status");
      document.getElementById("currentEmploymentStatusSelect").focus();
      return;
    }

    if (mainReasonForThisSkill.trim() === "") {
      toast.error(
        "Please select the main reason for undertaking this skills assessment"
      );
      document.getElementById("mainReasonForThisSkillSelect").focus();
      return;
    }

    // Navigate to Education & Employment
    // navigate("/user/create_new_application/stage_1/review_and_confirm", { state: { pointerData } });
    // toast.success("Education & Employment Details saved successfully.");

    // Construct form data object
    const formData = new FormData();
    formData.append("pointer_id", pointerData?.pointer_id);
    formData.append(
      "highest_completed_school_level",
      heighestCompletedSchoolLevel
    );
    formData.append(
      "still_enrolled_in_secondary_or_senior_secondary_education",
      stillEnrolledInSecondaryEducation
    );
    formData.append(
      "completed_any_qualifications",
      successfullyCompletredAnyQualifications
    );
    formData.append(
      "applicable_qualifications",
      successfullyCompletredAnyQualifications === "yes"
        ? chooseApplicableQualification
        : ""
    );
    formData.append("current_employment_status", currentEmploymentStatus);
    formData.append(
      "reason_for_undertaking_this_skills_assessment",
      mainReasonForThisSkill
    );
    formData.append("other_reason_for_undertaking", "");
    formData.append("status", "");

    // API call to save Education & Employment details
    try {
      setLoading(true);

      const response = await createEducationAndEmployment_stage1_API(formData);
      setLoading(false);

      if (response?.data?.response?.response === true) {
        navigate("/user/create_new_application/stage_1/review_and_confirm", {
          state: { pointerData },
        });
        toast.success("Education & Employment Details saved successfully.");
      } else {
        // If API call fails, display error message
        toast.error(
          response?.data?.response?.error_msg ||
            "Failed to save Education & Employment Details"
        );
      }
    } catch (error) {
      setLoading(false);

      console.error("Error saving Education & Employment Details:", error);
      toast.error("Failed to save Education & Employment Details");
    }
  };

  const handleSaveAndExit = async () => {
    setShowConfirmationModal(true);
  };

  useEffect(() => {
    const getEducationDetailsFunction = async () => {
      try {
        setLoading(true);
        const response = await getEducationAndEmployment_stage1_API(
          pointerData?.pointer_id
        );
        setLoading(false);

        if (
          response &&
          response?.data &&
          response?.data?.response &&
          response?.data?.response?.data
        ) {
          const stage_1_Education_details =
            response?.data?.response?.data?.education_and_employment;

          setHeighestCompletedSchoolLevel(
            stage_1_Education_details.highest_completed_school_level
          );
          setstillEnrolledInSecondaryEducation(
            stage_1_Education_details.still_enrolled_in_secondary_or_senior_secondary_education
          );
          setSuccessfullyCompletredAnyQualifications(
            stage_1_Education_details.completed_any_qualifications
          );
          setChooseApplicableQualification(
            stage_1_Education_details.applicable_qualifications
          );
          setCurrentEmploymentStatus(
            stage_1_Education_details.current_employment_status
          );
          setMainReasonForThisSkill(
            stage_1_Education_details.reason_for_undertaking_this_skills_assessment
          );
        } else {
          setLoading(false);

          console.error(
            "Error fetching occupations data:",
            response?.data?.error_msg || "Unknown error"
          );
        }
      } catch (error) {
        setLoading(false);

        console.error("Error fetching occupations data:", error);
      }
    };

    if (pointerData?.pointer_id) {
      getEducationDetailsFunction(pointerData?.pointer_id);
    }
  }, [pointerData?.pointer_id]);

  return (
    <>
      <div className="forgot-container-new-USI">
        <Card className="shadow forgot-card-new-USI">
          <div
            className="card-header text-center"
            style={{ fontSize: "20px", color: "#055837" }}
          >
            <b>Education & Employment</b>
          </div>
          <Card.Body>
            <div className="row">
              <div className="col-md-7"></div>
              <div className="col-md-5 d-flex justify-content-end">
                <div className="mb-3 mt-1">
                  Portal Reference No. :{" "}
                  {/* <b style={{ color: "#055837" }}>24AQ063</b> */}
                  <b style={{ color: "#055837" }}>
                    {pointerData?.portal_refrance_no}
                  </b>
                </div>
              </div>
            </div>

            <Form>
              <div className="account-details">
                <div style={{ marginTop: "5px" }}>
                  <Card className="shadow">
                    <div
                      className="card-header text-center"
                      style={{ fontSize: "20px", color: "#055837" }}
                    >
                      <b>Education Details</b>
                    </div>
                    <Card.Body>
                      <Form onSubmit={handleNext}>
                        <div className="row mb-3 bg-offwhite">
                          <div className="col-md-5 d-flex align-items-center justify-content-start">
                            <Form.Group controlId="heighestCompletedSchoolLevelLebel">
                              <Form.Label
                                htmlFor="heighestCompletedSchoolLevelSelect"
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                  paddingLeft: "10px",
                                }}
                              >
                                ▸ What is your highest COMPLETED school level ?{" "}
                                <span style={{ color: "red" }}>*</span>
                              </Form.Label>
                            </Form.Group>
                          </div>
                          <div className="col-md-7">
                            <Form.Group controlId="heighestCompletedSchoolLevelSelect">
                              <Form.Select
                                value={heighestCompletedSchoolLevel}
                                onChange={(e) =>
                                  setHeighestCompletedSchoolLevel(
                                    e.target.value
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <option
                                  className="dropdown-options"
                                  value=""
                                  disabled
                                  selected
                                >
                                  Select an option
                                </option>
                                <option
                                  className="dropdown-options"
                                  value="Year 8 or below"
                                >
                                  Year 8 or below
                                </option>
                                <option
                                  className="dropdown-options"
                                  value="Year 9 or equivalent"
                                >
                                  Year 9 or equivalent
                                </option>
                                <option
                                  className="dropdown-options"
                                  value="Year 10 or equivalent"
                                >
                                  Year 10 or equivalent
                                </option>
                                <option
                                  className="dropdown-options"
                                  value="Year 11 or equivalent"
                                >
                                  Year 11 or equivalent
                                </option>
                                <option
                                  className="dropdown-options"
                                  value="Year 12 or equivalent"
                                >
                                  Year 12 or equivalent
                                </option>
                              </Form.Select>
                            </Form.Group>
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-md-5 d-flex align-items-center justify-content-start">
                            <Form.Group controlId="stillEnrolledInSecondaryEducationLabel">
                              <Form.Label
                                htmlFor="stillEnrolledInSecondaryEducationSelect"
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                  paddingLeft: "10px",
                                  fontSize: "15px",
                                }}
                              >
                                ▸ Are you still enrolled in secondary or senior
                                secondary education ?{" "}
                                <span style={{ color: "red" }}>*</span>
                              </Form.Label>
                            </Form.Group>
                          </div>
                          <div className="col-md-7 d-flex align-items-center">
                            <Form.Group
                              controlId="stillEnrolledInSecondaryEducation"
                              className="d-flex"
                            >
                              <Form.Check
                                type="radio"
                                label="Yes"
                                value="yes"
                                id="stillEnrolledInSecondaryEducationYes"
                                name="stillEnrolledInSecondaryEducationYes"
                                className="me-5 radio-with-border"
                                style={{ cursor: "pointer" }}
                                checked={
                                  stillEnrolledInSecondaryEducation === "yes"
                                }
                                onChange={(e) =>
                                  setstillEnrolledInSecondaryEducation(
                                    e.target.value
                                  )
                                }
                              />
                              <Form.Check
                                type="radio"
                                label="No"
                                value="no"
                                className="me-5 radio-with-border"
                                style={{ cursor: "pointer" }}
                                id="stillEnrolledInSecondaryEducationNo"
                                name="stillEnrolledInSecondaryEducationNo"
                                checked={
                                  stillEnrolledInSecondaryEducation === "no"
                                }
                                onChange={(e) =>
                                  setstillEnrolledInSecondaryEducation(
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Group>
                          </div>
                        </div>

                        <div className="row mb-2 bg-offwhite">
                          <div className="col-md-5 d-flex align-items-center justify-content-start">
                            <Form.Group controlId="successfullyCompletredAnyQualificationsLabel">
                              <Form.Label
                                htmlFor="successfullyCompletredAnyQualificationsSelect"
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                  paddingLeft: "10px",
                                }}
                              >
                                ▸ Have you SUCCESSFULLY completed any
                                qualifications ?
                                <span style={{ color: "red" }}>*</span>
                              </Form.Label>
                            </Form.Group>
                          </div>
                          <div className="col-md-7 d-flex align-items-center">
                            <Form.Group
                              controlId="successfullyCompletredAnyQualificationsSelect"
                              className="d-flex"
                            >
                              <Form.Check
                                type="radio"
                                label="Yes"
                                value="yes"
                                id="successfullyCompletredAnyQualificationsSelectYes"
                                name="successfullyCompletredAnyQualificationsSelectYes"
                                className="me-5 radio-with-border"
                                style={{ cursor: "pointer" }}
                                checked={
                                  successfullyCompletredAnyQualifications ===
                                  "yes"
                                }
                                onChange={(e) =>
                                  setSuccessfullyCompletredAnyQualifications(
                                    e.target.value
                                  )
                                }
                              />
                              <Form.Check
                                type="radio"
                                label="No"
                                value="no"
                                className="me-5 radio-with-border"
                                style={{ cursor: "pointer" }}
                                id="successfullyCompletredAnyQualificationsSelectNo"
                                name="successfullyCompletredAnyQualificationsSelectNo"
                                checked={
                                  successfullyCompletredAnyQualifications ===
                                  "no"
                                }
                                onChange={(e) =>
                                  setSuccessfullyCompletredAnyQualifications(
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Group>
                          </div>

                          {successfullyCompletredAnyQualifications ===
                            "yes" && (
                            <div className="row mb-3">
                              <div className="col-md-5 d-flex align-items-center justify-content-start">
                                <Form.Group controlId="chooseApplicableQualificationLabel">
                                  <Form.Label
                                    htmlFor="chooseApplicableQualificationSelect"
                                    style={{
                                      fontWeight: "bold",
                                      textAlign: "left",
                                      paddingLeft: "10px",
                                    }}
                                  >
                                    ▸ Please choose applicable qualifications{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </Form.Label>
                                </Form.Group>
                              </div>
                              <div className="col-md-7 mt-1">
                                <select
                                  controlId="chooseApplicableQualificationSelect"
                                  className="form-select"
                                  aria-label="Default select example"
                                  onChange={(e) =>
                                    setChooseApplicableQualification(
                                      e.target.value
                                    )
                                  }
                                  value={chooseApplicableQualification}
                                  id="chooseApplicableQualificationSelect"
                                >
                                  <option value="" disabled defaultValue>
                                    Select An Option
                                  </option>
                                  <option value="Certificate_I">
                                    Certificate I
                                  </option>
                                  <option value="Certificate_II_Other_Certificates">
                                    Certificate II Other Certificates
                                  </option>
                                  <option value="Certificate_III_or_Trade_Certificate">
                                    Certificate III or Trade Certificate
                                  </option>
                                  <option value="Certificate_IV_or_Advanced_Certificate">
                                    Certificate IV or Advanced Certificate
                                  </option>
                                  <option value="Diploma_or_Associate_Diploma">
                                    Diploma or Associate Diploma
                                  </option>
                                  <option value="Advanced_Diploma_or-Associate-Degree">
                                    Advanced Diploma or Associate Degree
                                  </option>
                                  <option value="Bachelor_Degree_or_Higher">
                                    Bachelor Degree or Higher
                                  </option>
                                  <option value="Other_Education">
                                    Other Education
                                  </option>
                                </select>
                              </div>
                            </div>
                          )}
                        </div>
                      </Form>
                    </Card.Body>
                  </Card>
                </div>

                <div style={{ marginTop: "25px" }}>
                  <Card className="shadow">
                    <div
                      className="card-header text-center"
                      style={{ fontSize: "20px", color: "#055837" }}
                    >
                      <b>Employment Details</b>
                    </div>
                    <Card.Body>
                      <Form onSubmit={handleNext}>
                        <div className="row mb-3 bg-offwhite">
                          <div className="col-md-5 d-flex align-items-center justify-content-start">
                            <Form.Group controlId="currentEmploymentStatusLabel">
                              <Form.Label
                                htmlFor="currentEmploymentStatusSelect"
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                  paddingLeft: "10px",
                                }}
                              >
                                ▸ What is your current employment status ?{" "}
                                <span style={{ color: "red" }}>*</span>
                              </Form.Label>
                            </Form.Group>
                          </div>
                          <div className="col-md-7">
                            <Form.Group controlId="currentEmploymentStatusSelect">
                              <Form.Select
                                id="currentEmploymentStatusSelect"
                                value={currentEmploymentStatus}
                                onChange={(e) =>
                                  setCurrentEmploymentStatus(e.target.value)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <option value="" disabled selected>
                                  Select An Option
                                </option>
                                <option value="Full_Time_Employee">
                                  Full Time Employee
                                </option>
                                <option value="Part_Time_Employee">
                                  Part Time Employee
                                </option>
                                <option value="Self_Employed_not_employing_others">
                                  Self Employed, not employing others
                                </option>
                                <option value="Self_Employed_employing_others">
                                  Self Employed, employing others
                                </option>
                                <option value="Employed_unpaid_worker_in_family_business">
                                  Employed, unpaid worker in family business
                                </option>
                                <option value="Unemployed_seeking_full_time_work">
                                  Unemployed, seeking full-time work
                                </option>
                                <option value="Unemployed_seeking_part_time_work">
                                  Unemployed, seeking part time work
                                </option>
                                <option value="Not_employed_not_seeking_employment">
                                  Not employed, not seeking employment
                                </option>
                              </Form.Select>
                            </Form.Group>
                          </div>
                        </div>

                        <div className="row mb-0">
                          <div className="col-md-5 d-flex align-items-center justify-content-start">
                            <Form.Group controlId="mainReasonForThisSkillLabel">
                              <Form.Label
                                htmlFor="mainReasonForThisSkillSelect"
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                  paddingLeft: "10px",
                                }}
                              >
                                ▸ What BEST describes your main reason for
                                undertaking this skills assessment ?{" "}
                                <span style={{ color: "red" }}>*</span>
                              </Form.Label>
                            </Form.Group>
                          </div>
                          <div className="col-md-7">
                            <Form.Group controlId="mainReasonForThisSkillSelect">
                              <Form.Select
                                id="mainReasonForThisSkillSelect"
                                value={mainReasonForThisSkill}
                                onChange={(e) =>
                                  setMainReasonForThisSkill(e.target.value)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <option value="" disabled defaultValue>
                                  Select An Option
                                </option>
                                <option value="To_get_a_job">
                                  To get a job
                                </option>
                                <option value="To_develop_my_existing_business">
                                  To develop my existing business
                                </option>
                                <option value="To_start_my_own_business">
                                  To start my own business
                                </option>
                                <option value="To_try_a_different_career">
                                  To try a different career
                                </option>
                                <option value="To_get_a_better_job_or_promotion">
                                  To get a better job or promotion
                                </option>
                                <option value="It_was_a_requirement_of_my_job">
                                  It was a requirement of my job
                                </option>
                                <option value="I_wanted_extra_skills_for_my_job">
                                  I wanted extra skills for my job
                                </option>
                                <option value="To_get_into_another_course_of_study">
                                  To get into another course of study
                                </option>
                                <option value="For_personal_interest_or_self_development">
                                  For personal interest or self-development
                                </option>
                                <option value="To_get_skills_for_community_voluntary_work">
                                  To get skills for community/ voluntary work
                                </option>
                                <option value="Other_Reasons_Skills_Assessment_(VISA)">
                                  Other Reasons - Skills Assessment (VISA)
                                </option>
                                <option value="Other_reasons">
                                  Other reasons
                                </option>
                              </Form.Select>
                            </Form.Group>
                          </div>
                        </div>
                      </Form>
                    </Card.Body>
                  </Card>
                </div>
              </div>

              {/* {loading && (
                <div className="d-flex justify-content-center mt-3">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              )} */}

              {loading && <Loader />}

              {/* Back and Save & Exit and Next Buttons */}
              <div
                className="button-group text-center mb-1"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <div
                  className="button-group text-center mb-1"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  {/* Back Button */}
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{
                      backgroundColor: "#ffcc01",
                      color: "#055837",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                      border: "none",
                      borderRadius: "5px",
                      marginTop: "30px",
                      marginBottom: "20px",
                      transition: "background-color 0.3s, color 0.3s",
                    }}
                    onClick={handleBack}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = "#055837";
                      e.target.style.color = "#ffcc01";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "#ffcc01";
                      e.target.style.color = "#055837";
                    }}
                  >
                    Back
                  </button>

                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSaveAndExit}
                    style={{
                      backgroundColor: "#055837",
                      color: "#ffcc01",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                      border: "none",
                      borderRadius: "5px",
                      marginTop: "30px",
                      marginBottom: "20px",
                      transition: "background-color 0.3s, color 0.3s",
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = "#ffcc01";
                      e.target.style.color = "#055837";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "#055837";
                      e.target.style.color = "#ffcc01";
                    }}
                  >
                    Save & Exit
                  </button>

                  {/* Next Button */}
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{
                      backgroundColor: "#ffcc01",
                      color: "#055837",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                      border: "none",
                      borderRadius: "5px",
                      marginTop: "30px",
                      marginBottom: "20px",
                      transition: "background-color 0.3s, color 0.3s",
                    }}
                    onClick={handleNext}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = "#055837";
                      e.target.style.color = "#ffcc01";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "#ffcc01";
                      e.target.style.color = "#055837";
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </Form>
          </Card.Body>

          <ConfirmationModal
            show={showConfirmationModal}
            onHide={handleConfirmationNo}
            onConfirm={handleConfirmationYes}
            title="Confirm Leaving"
            message="Are you sure you want to leave this page? You don't want to continue this new application process right now? If you proceed, you'll be redirected to the dashboard."
          />

          {backButtonClicked && (
            <ConfirmationModal
              show={showConfirmationBackModal}
              onHide={handleConfirmationBackNo}
              onConfirm={handleConfirmationBackYes}
              title="Confirm Navigation"
              message="Are you sure you want to go back to the USI & Avetmiss form page?"
            />
          )}
        </Card>
      </div>
    </>
  );
};

export default EducationAndEmployment;
