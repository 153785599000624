import React, { useState, useEffect } from "react";
import "./Assessment Documents.css";
import { useLocation } from "react-router-dom";
import { XCircleFill, CheckCircleFill } from "react-bootstrap-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, Form, Button, Spinner } from "react-bootstrap";

import UploadModal from "./Upload Modal/Upload Modal";
import Others from "./Others/Others";
import {
  getuploadedDocumentsList_stage2_Assessment_API,
  stage2_get_assessments_docs_names,
} from "../../../../../api";

const AssessmentDocuments = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const pointerData = location.state?.pointerData;

  const [AssessmentDocsList, setAssessmentDocsList] = useState([]);
  const [uploadedDocs, setUploadedDocs] = useState([]);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [currentDoc, setCurrentDoc] = useState(null);

  useEffect(() => {
    const fetchAssessmentsList = async () => {
      // Construct form data object
      const formData = new FormData();
      formData.append("pointer_id", pointerData?.pointer_id);
      formData.append("stage", "stage_2");

      try {
        const response = await stage2_get_assessments_docs_names(formData);
        if (
          response?.data?.response &&
          response?.data?.response?.response === true &&
          response?.data?.response?.data
        ) {
          setAssessmentDocsList(response?.data?.response?.data);
        } else {
          console.error(
            "Error fetching assessment documents:",
            response?.data?.error_msg || "Unknown error"
          );
        }
      } catch (error) {
        console.error("Error fetching assessment documents:", error);
      }
    };

    if (pointerData?.pointer_id) {
      fetchAssessmentsList();
    }
  }, [pointerData?.pointer_id]);

  const getUploadedDocsList = async () => {
    try {
      // setLoading(true);
      const response = await getuploadedDocumentsList_stage2_Assessment_API(
        pointerData?.pointer_id
      );
      // setLoading(false);
      if (response && response?.data && response?.data?.response) {
        setUploadedDocs(response.data.response.data);
      } else {
        console.error(
          "Error fetching data:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      // setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (pointerData?.pointer_id) {
      getUploadedDocsList();
    }
  }, [pointerData?.pointer_id]);

  const handleOpenPopupModal = (doc) => {
    setCurrentDoc(doc);
    setShowUploadModal(true);
  };

  const handleClosePopupModal = () => {
    setShowUploadModal(false);
    setCurrentDoc(null);
  };

  const isDocUploaded = (docId) => {
    const doc = uploadedDocs.find((item) => item.id === docId);
    return doc && doc.status === 1;
  };

  const renderUploadModal = (doc) => {
    const allowedTypes = JSON.parse(doc?.allowed_type || "[]");

    if (doc?.is_multiple !== "1") {
      return (
        <UploadModal
          show={showUploadModal && currentDoc?.id === doc.id}
          onHide={handleClosePopupModal}
          pointerData={pointerData}
          getUploadedDocsList={getUploadedDocsList}
          ModalHeading={doc.document_name}
          ModalID={doc?.id}
          allowedTypes={allowedTypes}
          is_required={doc.is_required}
        />
      );
    } else {
      return (
        <Others
          show={showUploadModal && currentDoc?.id === doc.id}
          onHide={handleClosePopupModal}
          pointerData={pointerData}
          getUploadedDocsList={getUploadedDocsList}
          ModalHeading={doc.document_name}
          ModalID={doc?.id}
          allowedTypes={allowedTypes}
          is_required={doc.is_required}


        />
      );
    }
  };

  return (
    <div className="documentary-upload-docs-emp-container-contact-details">
      <Card className="shadow documentary-upload-docs-emp-card-contact-details">
        <div
          className="card-header text-center"
          style={{ fontSize: "20px", color: "#055837",cursor:"default" }}
        >
          <b>Assessment Documents</b>
        </div>
        <Form>
          <div
            className="row mb-3"
            style={{ margin: "5px", marginTop: "15px" }}
          >
            <div className="col-md-12">
              {loading ? (
                <div className="d-flex justify-content-center mt-3">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                AssessmentDocsList.map((doc) =>
                  isDocUploaded(doc.id) ? (
                    <div
                      key={doc.id}
                      className="docUploaded"
                      style={{
                        marginBottom: "15px",
                        border: "1px solid #055837",
                        borderRadius: "5px",
                      }}
                    >
                      <div className="shadow p-4">
                        <div className="accordion-header-wrapper">
                          <button
                            className="accordion-button"
                            type="button"
                            onClick={() => handleOpenPopupModal(doc)}
                          >
                            <p className="accordion-header" id="headingOne">
                              <div className="d-flex align-items-center">
                                <span style={{ marginRight: "10px" }}>
                                  <CheckCircleFill />
                                </span>
                                <span>
                                  {doc.document_name}{" "}
                                  {doc.is_required === "1" && (
                                    <span style={{ color: "red" }}>*</span>
                                  )}{" "}
                                   {/* - Uploaded Successfully! */}
                                </span>
                              </div>
                            </p>
                          </button>
                          <span style={{ marginRight: "20px" }}>✔️</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      key={doc.id}
                      className="bg-white shadow p-4 docNotUploaded"
                      style={{
                        marginBottom: "15px",
                        border: "1px solid #ffcc01",
                        borderRadius: "5px",
                      }}
                    >
                      <div className="accordion-header-wrapper">
                        <button
                          className="accordion-button"
                          type="button"
                          onClick={() => handleOpenPopupModal(doc)}
                        >
                          <p className="accordion-header" id="headingOne">
                            <div className="d-flex align-items-center">
                              <span className="bullet-point"></span>
                              <span>
                                {doc.document_name}{" "}
                                {doc.is_required === "1" && (
                                  <span style={{ color: "red" }}>*</span>
                                )}
                              </span>
                            </div>
                          </p>
                        </button>
                        <span style={{ marginRight: "20px" }}>⟫</span>
                      </div>
                    </div>
                  )
                )
              )}
            </div>
          </div>
        </Form>
      </Card>
      {AssessmentDocsList.map((doc) => (
        <React.Fragment key={doc.id}>{renderUploadModal(doc)}</React.Fragment>
      ))}
    </div>
  );
};

export default AssessmentDocuments;
