import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import { Card, Col, Row, Table } from "react-bootstrap";

import "./Edit Employment Details Modal.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  stage2_update_employment_details,
  getAllCountryDataAPI,
  get_One_EmploymentDetailsForTable_stage2_API,
} from "../../../../api";
import Loader from "../../../Loader/Loader";

const EditEmploymentDetailsModal = ({
  show,
  onHide,
  pointer_id,
  getEmploymentDataFunction,
  employee_id,
}) => {
  const [countriesData, setCountriesData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Fetch countries when component mounts
    const fetchCountries = async () => {
      try {
        const response = await getAllCountryDataAPI();
        if (response.data?.response && response.data?.response?.data) {
          setCountriesData(response.data.response.data);
        } else {
          console.error(
            "Error fetching countries:",
            response.data?.error_msg || "Unknown error"
          );
        }
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

  const [CheckMail, setCheckMail] = useState(false);


  const [selectEmploymentType, setselectEmploymentType] = useState("");
  const [companyNameOrOrganisation, setCompanyNameOrOrganisation] =
    useState("");
  const [enterRefereeName, setEnterRefereeName] = useState("");
  const [refreeMobileCountryCode, setRefreeMobileCountryCode] = useState("13");
  const [refreeMobileNumber, setRefreeMobileNumber] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [confirmRefereeEmail, setConfirmRefereeEmail] = useState("");
  const [confirmEmailError, setConfirmEmailError] = useState(false);

  const handleEmailChange = (event) => {
    const input = event.target.value;
    setEmail(input);

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(input)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };

  const handleConfirmEmailChange = (e) => {
    const value = e.target.value;
    setConfirmRefereeEmail(value);
    setConfirmEmailError(false);
    setCheckMail(true);
  };

  useEffect(() => {
    if (confirmRefereeEmail !== email) {
      setConfirmEmailError(true);
    } else {
      setConfirmEmailError(false);
    }
  }, [confirmRefereeEmail, email]);

  const handleNextSubmitUpdate = async (e) => {
    e.preventDefault();

    if (companyNameOrOrganisation.trim() === "") {
      toast.error("Please enter your Company/Organisation Name.");

      document.getElementById("companyNameOrOrganisationInput").focus();
      return;
    }

    if (selectEmploymentType.trim() === "") {
      toast.error("Please select your Employment Type.");
      document.getElementById("selectEmploymentTypeSelect").focus();
      return;
    }

    if (enterRefereeName.trim() === "") {
      toast.error("Please enter your Referee Name.");
      document.getElementById("enterRefereeNameInput").focus();
      return;
    }

    if (refreeMobileCountryCode.trim() === "") {
      toast.error("Please select your Referee country code.");
      document.getElementById("refreeMobileCountryCode").focus();
      return;
    }

    if (refreeMobileNumber.trim() === "") {
      toast.error("Please enter your Referee Contact number.");
      document.getElementById("refreeMobileNumber").focus();
      return;
    }

    // if (email.trim() === "") {
    //   toast.error("Please enter your Referee Email.");
    //   document.getElementById("refereeEmailInput").focus();
    //   return;
    // }

    // if (confirmRefereeEmail.trim() === "") {
    //   toast.error("Please confirm your Referee Email.");
    //   document.getElementById("confirmRefereeEmailInput").focus();
    //   return;
    // }

    // const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // if (!emailPattern.test(email.trim())) {
    //   toast.error("Please enter a valid Referee Email address.");
    //   document.getElementById("refereeEmailInput").focus();
    //   return;
    // }

    // if (!emailPattern.test(confirmRefereeEmail.trim())) {
    //   toast.error("Please enter a valid Confirm Referee Email address.");
    //   document.getElementById("confirmRefereeEmailInput").focus();
    //   return;
    // }

    // if (email.trim() !== confirmRefereeEmail.trim()) {
    //   toast.error("Emails don't match.");
    //   document.getElementById("confirmRefereeEmailInput").focus();
    //   setConfirmEmailError(true);
    //   return;
    // }



    if (email.trim() === "") {
      toast.error("Please enter your Referee Email.");
      document.getElementById("refereeEmailInput").focus(); // This ID needs to match
      return;
    }

    if (confirmRefereeEmail.trim() === "") {
      toast.error("Please confirm your Referee Email.");
      document.getElementById("confirmRefereeEmailInput").focus(); // This ID needs to match
      return;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailPattern.test(email.trim())) {
      toast.error("Please enter a valid Referee Email address.");
      document.getElementById("refereeEmailInput").focus();
      return;
    }

    if (!emailPattern.test(confirmRefereeEmail.trim())) {
      toast.error("Please enter a valid Confirm Referee Email address.");
      document.getElementById("confirmRefereeEmailInput").focus();
      return;
    }

    if (email.trim() !== confirmRefereeEmail.trim()) {
      toast.error("Emails don't match.");
      document.getElementById("confirmRefereeEmailInput").focus();
      setConfirmEmailError(true);
      return;
    }


    // if (!termsChecked) {

    //   toast.error("Agree to the terms.");
    //   document.getElementById("termsAndConditions").focus();
    //   return;

    // }

    // Construct form data object
    const formData = new FormData();
    formData.append("pointer_id", pointer_id);
    formData.append("employe_id", employee_id);

    formData.append("company_organisation_name", companyNameOrOrganisation);
    formData.append("employment_type", selectEmploymentType);

    formData.append("referee_name", enterRefereeName);
    formData.append("referee_mobile_number_code", refreeMobileCountryCode);
    formData.append("referee_mobile_number", refreeMobileNumber);
    formData.append("referee_email", email);
    formData.append("Confirm_referee_email", confirmRefereeEmail);

    // API call to save Employment Details
    try {
      setLoading(true);

      const response = await stage2_update_employment_details(
        formData,
        pointer_id
      );
      setLoading(false);

      if (response?.data?.response?.response === true) {
        toast.success("Employment Details updated successfully.");
        getEmploymentDataFunction();
        onHide();
      } else {
        // If API call fails, display error message
        toast.error(
          response?.data?.response?.error_msg ||
            "Failed to save Employment Details"
        );
      }
    } catch (error) {
      setLoading(false);

      console.error("Error saving Employment Details:", error);
      toast.error("Failed to save Employment Details");
    }
  };

  useEffect(() => {
    const get_One_EmploymentDetailsForTableFunction = async () => {
      try {
        setLoading(true);
        const response = await get_One_EmploymentDetailsForTable_stage2_API(
          pointer_id,
          employee_id
        );
        setLoading(false);

        if (
          response &&
          response?.data &&
          response?.data?.response &&
          response?.data?.response?.data
        ) {
          const employmentDetails = response?.data?.response?.data;

          setCompanyNameOrOrganisation(
            employmentDetails?.company_organisation_name
          );
          setselectEmploymentType(employmentDetails?.employment_type);
          setEnterRefereeName(employmentDetails?.referee_name);
          setRefreeMobileCountryCode(
            employmentDetails?.referee_mobile_number_code
          );
          setRefreeMobileNumber(employmentDetails?.referee_mobile_number);
          setEmail(employmentDetails?.referee_email);
          setConfirmRefereeEmail(employmentDetails?.referee_email);
        } else {
          setLoading(false);
          console.error(
            "Error fetching employment details:",
            response?.data?.response?.error_msg || "Unknown error"
          );
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching employment details:", error);
      }
    };

    if (pointer_id && employee_id) {
      get_One_EmploymentDetailsForTableFunction(pointer_id, employee_id);
    }
  }, [pointer_id, employee_id]);

  return (
    <Modal show={show} onHide={onHide} centered dialogClassName="custom-modal">
      <Modal.Header closeButton>
        <Modal.Title
          className="text-center"
          style={{
            fontSize: "18px",
            fontFamily: "Arial, sans-serif",
            color: "#055837",
          }}
        >
          Edit Employment Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <div className="documentary-container-contact-details">
            
            {/* <Card className="shadow documentary-card-contact-details"> */}

              <Card.Body>
                
                {/* {loading && (
                  <div className="d-flex justify-content-center mt-3">
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                )} */}

                {loading && <Loader />}

                <Form onSubmit={handleNextSubmitUpdate}>
                  <div className="row mb-0">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="companyNameOrOrganisationInput">
                          ▸ Company / Organisation Name{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="companyNameOrOrganisationInput"
                          name="companyNameOrOrganisationInput"
                          placeholder="Enter Company / Organisation Name"
                          disabled
                          value={companyNameOrOrganisation}
                          onChange={(e) =>
                            setCompanyNameOrOrganisation(e.target.value)
                          }
                          style={{ height: "40px" }}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Form.Group controlId="formBasicSurnamePostal">
                        <Form.Label htmlFor="selectEmploymentTypeSelect">
                          ▸ Employment Type{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <div className="form-group">
                          <select
                            className="form-select"
                            id="selectEmploymentTypeSelect"
                            name="selectEmploymentType"
                            disabled
                            value={selectEmploymentType}
                            onChange={(e) =>
                              setselectEmploymentType(e.target.value)
                            }
                            style={{ height: "40px" }}
                          >
                            <option value="" disabled>
                              Select Employment Type
                            </option>
                            <option>Full time/ Part time/ Casual</option>
                            <option>Self employed</option>
                          </select>
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row mb-0 mt-0">
                    <div className="col-md-6 mb-2">
                      <Form.Group controlId="formBasicStreetPostal">
                        <Form.Label htmlFor="enterRefereeNameInput">
                          ▸ Referee Name <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <input
                          type="text"
                          className="form-control"
                          id="enterRefereeNameInput"
                          name="enterRefereeName"
                          placeholder="Enter Referee Name"
                          value={enterRefereeName}
                          onChange={(e) => setEnterRefereeName(e.target.value)}
                          style={{ height: "40px" }}
                        />
                      </Form.Group>
                    </div>

                    <div className="col-md-6">
                      <Form.Group controlId="formBasicMobileEmergency">
                        <Form.Label>
                          ▸ Referee Contact No.{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <select
                                id="refreeMobileCountryCode"
                                className="form-select"
                                style={{ height: "40px" }}
                                value={refreeMobileCountryCode}
                                onChange={(e) =>
                                  setRefreeMobileCountryCode(e.target.value)
                                }
                              >
                                <option value="">Select Country Code</option>
                                {/* Map over countries to generate options */}
                                {countriesData.map((country) => (
                                  <option
                                    key={country.id}
                                    className="dropdown-options"
                                    value={country.id}
                                  >
                                    {country.name} (+{country.phonecode})
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              {/* <label htmlFor="refreeMobileNumber">
                                  ▸ Phone Number
                                </label> */}
                              <input
                                type="number"
                                className="form-control"
                                id="refreeMobileNumber"
                                name="refreeMobileNumber"
                                placeholder="Enter Referee Phone Number"
                                style={{ height: "40px" }}
                                value={refreeMobileNumber}
                                onChange={(e) =>
                                  setRefreeMobileNumber(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </Form.Group>
                    </div>
                  </div>

                  {/* <div className="row mb-0">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="refereeEmailInput">
                          ▸ Referee Email{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="refereeEmailInput"
                          name="refereeEmailInput"
                          placeholder="Enter Referee Email"
                          value={email}
                          onChange={handleEmailChange}
                          style={{ height: "40px" }}
                        />
                      </div>

                      {emailError && (
                        <p className="text-danger">{emailError}</p>
                      )}
                    </div>

                    <div className="col-md-6">
                      <Form.Group controlId="formBasicConfirmRefereeEmail">
                        <Form.Label htmlFor="confirmRefereeEmailInput">
                          ▸ Confirm Referee Email{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <div className="form-group">
                          <input
                            type="text"
                            className={`form-control ${
                              emailError ? "is-invalid" : ""
                            }`}
                            id="confirmRefereeEmailInput"
                            name="confirmRefereeEmailInput"
                            placeholder="Confirm Referee Email"
                            value={confirmRefereeEmail}
                            onChange={handleConfirmEmailChange}
                            style={{ height: "40px" }}
                          />
                          {confirmEmailError && (
                            <p className="text-danger">Emails do not match</p>
                          )}
                        </div>
                      </Form.Group>
                    </div>
                  </div> */}


<div className="row mb-0">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="refereeEmailInput">
                            ▸ Referee Email{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              emailError ? "is-invalid" : ""
                            }`}
                            id="refereeEmailInput" // Correct ID
                            name="refereeEmailInput"
                            placeholder="Enter Referee Email"
                            value={email}
                            onChange={handleEmailChange}
                            style={{ height: "40px" }}
                          />
                        </div>

                        {emailError && (
                          <p className="text-danger">{emailError}</p>
                        )}
                      </div>

                      <div className="col-md-6">
                        <Form.Group controlId="formBasicConfirmRefereeEmail">
                          <Form.Label htmlFor="confirmRefereeEmailInput">
                            ▸ Confirm Referee Email{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                          <div className="form-group">
                            <input
                              type="text"
                              className={`form-control ${
                                CheckMail && confirmEmailError
                                  ? "is-invalid"
                                  : ""
                              }`}
                              id="confirmRefereeEmailInput" // Correct ID
                              name="confirmRefereeEmailInput"
                              placeholder="Confirm Referee Email"
                              value={confirmRefereeEmail}
                              onChange={handleConfirmEmailChange}
                              style={{ height: "40px" }}
                            />
                            {confirmEmailError && CheckMail && (
                              <p className="text-danger">Emails do not match</p>
                            )}
                          </div>
                        </Form.Group>
                      </div>
                    </div>



                  {/* <hr className="mb-2 mt-1 thick-hr" /> */}

                  
                </Form>
              </Card.Body>
            {/* </Card> */}
          </div>
        </>
      </Modal.Body>

      <Modal.Footer className="d-flex justify-content-center">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button
            type="button"
            className="btn btn-primary"
            style={{
              backgroundColor: "#ffcc01",
              color: "#055837",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
              border: "none",
              borderRadius: "5px",
              marginTop: "10px",
              marginBottom: "10px",
              marginRight: "10px",
              transition: "background-color 0.3s, color 0.3s",
            }}
            onClick={onHide}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = "#055837";
              e.target.style.color = "#ffcc01";
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = "#ffcc01";
              e.target.style.color = "#055837";
            }}
          >
            Cancel
          </button>

          <button
            type="button"
            className="btn btn-primary"
            onClick={handleNextSubmitUpdate}
            style={{
              backgroundColor: "#055837",
              color: "#ffcc01",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
              border: "none",
              borderRadius: "5px",
              marginTop: "10px",
              marginBottom: "10px",
              transition: "background-color 0.2s, color 0.2s",
            }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = "#ffcc01";
              e.target.style.color = "#055837";
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = "#055837";
              e.target.style.color = "#ffcc01";
            }}
          >
            Update
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default EditEmploymentDetailsModal;
