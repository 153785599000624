import React, { useState, useEffect } from "react";
import { Card, Form, Spinner } from "react-bootstrap";
import "./Employment Table.css";
import ConfirmationModal from "../../../Confirmation Modal/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { stage2_delete_employment_details } from "../../../../api";
import EditEmploymentDetailsModal from "../Edit Employment Details Modal/Edit Employment Details Modal";
import Loader from "../../../Loader/Loader";

const EmploymentTable = ({ originalData, getEmploymentDataFunction, pointer_id }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [deletingPersonName, setDeletingPersonName] = useState("");
  const [showConfirmationDeleteModal, setShowConfirmationDeleteModal] = useState(false);
  const [deleteButtonClicked, setDeleteButtonClicked] = useState(false);
  const [Employee_id, setEmployee_id] = useState(null);

  useEffect(() => {
    getEmploymentDataFunction();
  }, []);

  const handleConfirmationDeleteNo = () => {
    setShowConfirmationDeleteModal(false);
  };

  const handleDelete = (id, fullName) => {
    setEmployee_id(id);
    setDeleteButtonClicked(true);
    setShowConfirmationDeleteModal(true);
    setDeletingPersonName(fullName);
  };

  const handleConfirmationDeleteYes = async () => {
    const formData = new FormData();
    formData.append("employe_id", Employee_id);

    try {
      setLoading(true);
      const response = await stage2_delete_employment_details(formData, pointer_id);
      setLoading(false);

      if (response?.data?.response?.response === true) {
        setShowConfirmationDeleteModal(false);
        getEmploymentDataFunction();
        toast.success("Employment Detail Deleted successfully.");
      } else {
        toast.error(
          response?.data?.response?.error_msg || "Failed to Delete Employment Detail"
        );
      }
    } catch (error) {
      setLoading(false);
      toast.error("Failed to Delete Employment Detail");
    }
  };

  const handleEdit = (employee_id) => {
    setShowEditemploymentModalClicked(true);
    setEmployee_id(employee_id);
  };

  const [showEditEmploymentModalClicked, setShowEditemploymentModalClicked] = useState(false);

  const handleClosePopupModal = () => {
    setShowEditemploymentModalClicked(false);
  };

  return (
    <>
      {/* {loading && (
        <div className="d-flex justify-content-center mt-3">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )} */}

{loading && (
 <Loader />
      )}

      <div className="employment-table-info">
        <Card className="shadow">
          <Card.Body>
            <div id="table_wrapper" className="dataTables_wrapper no-footer">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="table-responsive">
                      <table className="table table-striped table-hover dataTable no-footer">
                        <thead>
                          <tr>
                            <th scope="col" className="col-1">Sr No.</th>
                            <th scope="col" className="col-4">Company / Organisation Name</th>
                            <th scope="col" className="col-3">Employment Type</th>
                            <th scope="col" className="col-3">Referee Email</th>
                            <th scope="col" className="col-1">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {originalData.map((item, index) => (
                            <tr key={index} className={index % 2 === 0 ? "even" : "odd"}>
                              <td><strong>{index + 1}</strong></td>
                              <td>{item.company_organisation_name}</td>
                              <td>{item.employment_type}</td>
                              <td>{item.referee_email}</td>
                              <td>
                                <div className="btn-container">
                                  <button
                                    className="btn btn-sm edit"
                                    style={{
                                      backgroundColor: "#055837",
                                      color: "#ffcc01",
                                      marginRight: "8px",
                                      transition: "background-color 0.2s, color 0.2s",
                                    }}
                                    onClick={() => handleEdit(item.id)}
                                  >
                                    <i className="bi bi-pencil-square"></i>
                                  </button>
                                  <button
                                    className="btn btn-sm btn-danger text-white"
                                    style={{ marginLeft: "8px" }}
                                    onClick={() => handleDelete(item.id, item.company_organisation_name)}
                                  >
                                    <i className="bi bi-trash-fill"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pagination-container">
                <div className="dataTables_info">
                  Showing {originalData.length} of {originalData.length} entries
                </div>
              </div>
            </div>
          </Card.Body>

          {deleteButtonClicked && (
            <ConfirmationModal
              show={showConfirmationDeleteModal}
              onHide={handleConfirmationDeleteNo}
              onConfirm={() => handleConfirmationDeleteYes(Employee_id)}
              title="Confirm Delete"
              message={`Are you sure you want to delete <strong>${deletingPersonName}</strong> Employment Details?`}
            />
          )}

          {showEditEmploymentModalClicked && (
            <EditEmploymentDetailsModal
              show={showEditEmploymentModalClicked}
              onHide={handleClosePopupModal}
              pointer_id={pointer_id}
              getEmploymentDataFunction={getEmploymentDataFunction}
              employee_id={Employee_id}
            />
          )}
        </Card>
      </div>
    </>
  );
};

export default EmploymentTable;
