import React, { useState, useEffect } from "react";
import "./Additional Information.css";
import { useLocation } from "react-router-dom";
import { XCircleFill, CheckCircleFill } from "react-bootstrap-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, Form, Button, Spinner, Row, Col } from "react-bootstrap";
import UploadModal from "./Upload Modal/Upload Modal";
import {
  FinalSubmissionAPIOfRequestData,
  getuploadedDocumentsList_AdditionalRequest_Assessment_API,
} from "../../../../api";
import AllTypeUpload from "./All Type Upload/All Type Upload";
import ConfirmationModal from "../../../Confirmation Modal/ConfirmationModal";

const AdditionalInformation = ({
  get_ViewApplication_Function,
  AdditionalInformationByAPI,
  pointerIDDDDD,
  ActiveStageByClick,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const pointerData = location.state?.pointerData;

  const [uploadedDocs, setUploadedDocs] = useState([]);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [currentDoc, setCurrentDoc] = useState(null);
  const [AssessmentDocsList, setAssessmentDocsList] = useState([]);
  const [CompanyDocMap, setCompanyDocMap] = useState({});
  const [IsObject, setIsObject] = useState(false);

  useEffect(() => {
    if (Array.isArray(AdditionalInformationByAPI)) {
      setAssessmentDocsList(AdditionalInformationByAPI);
      setIsObject(false);
    } else {
      const nestedArray = Object.values(AdditionalInformationByAPI).flat();
      const companyNames = Object.keys(AdditionalInformationByAPI);
      const companyDocMap = {};

      companyNames.forEach((company) => {
        AdditionalInformationByAPI[company].forEach((doc) => {
          companyDocMap[doc.id] = company;
        });
      });

      setAssessmentDocsList(nestedArray);
      setCompanyDocMap(companyDocMap);
      setIsObject(true);
    }
  }, [AdditionalInformationByAPI]);

  const getUploadedDocsList = async () => {
    const formData = new FormData();
    formData.append("pointer_id", pointerData?.pointer_id);
    formData.append("stage", ActiveStageByClick);

    try {
      setLoading(true);
      const response =
        await getuploadedDocumentsList_AdditionalRequest_Assessment_API(
          formData
        );
      setLoading(false);
      if (
        response &&
        response.data &&
        response.data.response &&
        response.data.response.response === true
      ) {
        setUploadedDocs(response.data.response.data);
      } else {
        console.error(
          "Error fetching data:",
          response.data.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (pointerData?.pointer_id) {
      getUploadedDocsList();
    }
  }, [pointerData?.pointer_id]);

  const handleOpenPopupModal = (doc) => {
    setCurrentDoc(doc);
    setShowUploadModal(true);
  };

  const handleClosePopupModal = () => {
    setShowUploadModal(false);
    setCurrentDoc(null);
  };

  const isDocUploaded = (id) => {
    const doc = uploadedDocs.find((item) => item?.id === id);
    return doc && doc?.is_file_uploaded === "1";
  };

  const renderUploadModal = (doc) => {
    const allowedTypes = doc?.document_required || "[]";

    // const inputDoc = doc?.is_additional === "1";
    const inputDoc = doc?.is_additional === "1" ? true : false;

    const EmploymentId =
      doc?.s2_add_employment_id === "0" ? "" : doc?.s2_add_employment_id;

    if (inputDoc) {
      return (
        <AllTypeUpload
          show={showUploadModal && currentDoc?.id === doc.id}
          onHide={handleClosePopupModal}
          pointerData={pointerData}
          getUploadedDocsList={getUploadedDocsList}
          ModalHeading={doc?.reason}
          ModalID={doc?.document_id}
          ModalAdditionalUniqueID={doc?.id}
          pointerIDDDDD={pointerIDDDDD}
          ActiveStageByClick={ActiveStageByClick}
          DocID={""}
          EmploymentId={EmploymentId}
          get_ViewApplication_Function={get_ViewApplication_Function}
          allowedTypes={allowedTypes}
          Reason={doc?.reason}
        />
      );
    } else if (!inputDoc) {
      return (
        <UploadModal
          show={showUploadModal && currentDoc?.id === doc.id}
          onHide={handleClosePopupModal}
          pointerData={pointerData}
          getUploadedDocsList={getUploadedDocsList}
          ModalHeading={doc?.document_name}
          ModalID={doc?.document_id}
          pointerIDDDDD={pointerIDDDDD}
          ActiveStageByClick={ActiveStageByClick}
          DocID={doc?.document_id}
          EmploymentId={EmploymentId}
          ModalAdditionalUniqueID={doc?.id}
          get_ViewApplication_Function={get_ViewApplication_Function}
          allowedTypes={allowedTypes}
          Reason={doc?.reason}
        />
      );
    } else {
      return (
        <AllTypeUpload
          show={showUploadModal && currentDoc?.id === doc.id}
          onHide={handleClosePopupModal}
          pointerData={pointerData}
          getUploadedDocsList={getUploadedDocsList}
          ModalHeading={doc?.reason}
          ModalID={doc?.document_id}
          pointerIDDDDD={pointerIDDDDD}
          ActiveStageByClick={ActiveStageByClick}
          DocID={doc?.document_id}
          EmploymentId={EmploymentId}
          ModalAdditionalUniqueID={doc?.id}
          get_ViewApplication_Function={get_ViewApplication_Function}
          allowedTypes={allowedTypes}
          Reason={doc?.reason}
        />
      );
    }
  };

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const FinalSubmissionAPI = () => {
    setShowConfirmationModal(true);
  };

  const handleConfirmationNo = () => {
    setShowConfirmationModal(false);
  };

  const handleConfirmationYes = async () => {
    const formData = new FormData();
    formData.append("pointer_id", pointerData?.pointer_id);
    formData.append("stage", ActiveStageByClick);

    try {
      setLoading(true);
      const response = await FinalSubmissionAPIOfRequestData(formData);
      setLoading(false);

      if (response && response.data && response.data.response) {
        if (response.data.response.response === true) {
          toast.success(
            response.data.response.success_msg || "Submitted Successfully"
          );
          get_ViewApplication_Function(pointerData?.pointer_id);
          setShowConfirmationModal(false);
        } else {
          toast.error(
            response.data.response.error_msg || "Unknown error occurred"
          );
        }
      } else {
        toast.error("Unexpected error: Invalid response structure");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
      toast.error("Error fetching data: " + (error.message || "Unknown error"));
    }
  };

  const allDocsUploaded = AssessmentDocsList.every((doc) =>
    isDocUploaded(doc.id)
  );

  // Group documents by company
  const groupedDocs = AssessmentDocsList.reduce((acc, doc) => {
    const companyName = CompanyDocMap[doc.id];
    if (!acc[companyName]) {
      acc[companyName] = [];
    }
    acc[companyName].push(doc);
    return acc;
  }, {});

  return (
    <>
      <div className="additionalInfo d-flex flex-column align-items-center">
        <div className="addiotional-upload-docs-emp-container-contact-details">
          <Card className="shadow addiotional-upload-docs-emp-card-contact-details">
            <div
              className="card-header"
              style={{ fontSize: "20px", color: "#055837" }}
            >
              <Row>
                <Col lg={9} className="my-auto">
                  <b>Action Required</b>
                </Col>

                <Col lg={3}>
                  
                  <Button
                      className={"btn btn_yellow_green w-30 float-end "}
                      variant="success"
                      onClick={FinalSubmissionAPI}
                      disabled={!allDocsUploaded}
                      style={{
                        margin: "10px",
                        color: "white",
                        backgroundColor: allDocsUploaded ? "#055837" : "#999999",
                        border: "none",
                      }}
                    >
                      Submit Additional Information
                    </Button>
                </Col>
              </Row>
              


            </div>
            <Form>
              <div
                className="row mb-1"
                style={{ margin: "5px", marginTop: "15px" }}
              >
                <div className="col-md-12">
                  {loading ? (
                    <div className="d-flex justify-content-center mt-3">
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    </div>
                  ) : (
                    Object.keys(groupedDocs).map((companyName) => (
                      <div key={companyName}>
                        {companyName !== undefined &&
                          companyName !== null &&
                          IsObject && (
                            <h3
                              style={{
                                textAlign: "center",
                                marginBottom: "10px",
                                color: "#055837",
                                fontWeight: "bold",
                              }}
                            >
                              <i
                                className="fas fa-city"
                                style={{ marginRight: "7px" }}
                              ></i>{" "}
                              {companyName}
                            </h3>
                          )}

                        {groupedDocs[companyName].map((doc) =>
                          isDocUploaded(doc.id) ? (
                            <div
                              key={doc.id}
                              className="docUploaded"
                              // style={{ marginBottom: "15px",border: "1px solid #055837" ,borderRadius:"5px",  }}
                              style={{
                                border: "1px solid #055837",
                                color: "#055837",
                                borderRadius: "5px",
                                marginBottom: "15px",
                              }}
                            >
                              <div className="shadow p-4">
                                <div className="accordion-header-wrapper">
                                  <button
                                    className="accordion-button"
                                    type="button"
                                    onClick={() => handleOpenPopupModal(doc)}
                                  >
                                    <p
                                      className="accordion-header"
                                      id="headingOne"
                                    >
                                      <div className="d-flex align-items-center">
                                        <span style={{ marginRight: "10px" }}>
                                          <CheckCircleFill />
                                        </span>
                                        <span>
                                          {doc?.document_id === null ||
                                          doc?.document_id === "0"
                                            ? doc?.reason
                                            : doc?.document_name}{" "}
                                          {
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          }{" "}
                                          {/* - Uploaded Successfully! */}
                                        </span>
                                      </div>
                                    </p>
                                  </button>
                                  <span style={{ marginRight: "20px" }}>
                                    ✔️
                                  </span>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div
                              key={doc?.id}
                              className="bg-white shadow p-4 docNotUploaded"
                              style={{
                                marginBottom: "15px",
                                border: "1px solid #ffcc01",
                              }}
                            >
                              <div className="accordion-header-wrapper">
                                <button
                                  className="accordion-button"
                                  type="button"
                                  onClick={() => handleOpenPopupModal(doc)}
                                >
                                  <p
                                    className="accordion-header"
                                    id="headingOne"
                                  >
                                    <div className="d-flex align-items-center">
                                      <span className="bullet-point"></span>
                                      <span>
                                        {doc?.document_id === null ||
                                        doc?.document_id === "0" ? (
                                          <span>
                                            {doc?.reason}{" "}
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          </span>
                                        ) : (
                                          <span>
                                            {doc?.document_name}{" "}
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>{" "}
                                            <br />
                                            <span style={{ color: "red" }}>
                                              ({doc?.reason})
                                            </span>
                                          </span>
                                        )}
                                      </span>
                                    </div>
                                  </p>
                                </button>
                                <span style={{ marginRight: "20px" }}>⟫</span>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    ))
                  )}
                </div>
              </div>
              <div className="button-container  d-flex justify-content-center align-items-center">
                {/* <Button
                  className="btn btn_green_yellow w-30"
                  variant="success"
                  onClick={FinalSubmissionAPI}
                  disabled={!allDocsUploaded}
                  style={{
                    margin: "10px",
                    color: "white",
                    backgroundColor: allDocsUploaded ? "#055837" : "#999999",
                    border: "none",
                  }}
                >
                  Submit Additional Information
                </Button> */}
              </div>
            </Form>
          </Card>
        </div>
      </div>
      {AssessmentDocsList.map((doc) => renderUploadModal(doc))}

      {showConfirmationModal && (
        <ConfirmationModal
          show={showConfirmationModal}
          onHide={handleConfirmationNo}
          onConfirm={handleConfirmationYes}
          title="Confirm Submit"
          message="Are you certain you wish to submit all documents required for action?"
        />
      )}
    </>
  );
};

export default AdditionalInformation;
