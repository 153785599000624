import React from "react";
import { Modal, Button } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";

const NotePopup = ({ show, onHide, title, matter }) => {
  return (
    <>
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header
          style={{ display: "flex", justifyContent: "center", textAlign: "center" }}
        >
          <Modal.Title
            style={{
              fontSize: "18px",
              fontFamily: "Arial, sans-serif",
              color: "#055837",
            }}
          >
         
            <strong>{title}</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="text-center"
          style={{ fontFamily: "Arial, sans-serif" }}
        >
        <div><strong>{matter}</strong></div>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-center">
          <Button
            variant="light"
            onClick={onHide}
            className="w-50"
            style={{
              maxWidth: "130px",
              backgroundColor: "#ffcc01",
              color: "#055837",
              transition: "transform 0.2s",
            }}
            onMouseEnter={(e) => (e.target.style.transform = "scale(1.02)")}
            onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NotePopup;
