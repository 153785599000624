import React from 'react'
import { Col, Row } from 'react-bootstrap'

export default function File_Uploader_Box({
    docs,
    setCurrentDocumentId,
    setConfirm_delete_popup
}) {
    return (
        <div className="file_uploader container">
            <Row>
                <Col sm={12}>
                    <ol>
                        {docs.map((doc) => (
                            <li className='my-2'>
                                <Row>
                                    <Col lg={10}>
                                        <a href={doc?.full_path} target='_blank'>
                                            {doc?.name}
                                        </a>
                                    </Col>
                                    <Col lg={2}>
                                        <button className='btn btn-sm btn-danger float-end' onClick={() => {
                                            setCurrentDocumentId(doc?.id)
                                            setConfirm_delete_popup(true);
                                        }}>
                                            <i className='fas fa-trash'></i>
                                        </button>
                                    </Col>
                                </Row>
                            </li>
                        ))}
                    </ol>
                </Col>
            </Row>
        </div>
    )
}
