import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { updateOldToNewPasswordAPI } from "../../api";
import Loader from "../Loader/Loader";

const UpdatePasswordModal = ({ show, handleClose ,userID}) => {
  const [loading, setLoading] = useState(false);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [oldPasswordError, setOldPasswordError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,20}$/;
    if (!passwordRegex.test(password)) {
      setPasswordError(
        "Password must be 8-20 characters, including at least one lowercase letter, one uppercase letter, one digit, and one special character. *"
      );
      return false;
    }
    setPasswordError("");
    return true;
  };

  const handleSubmitUpdatePassword = async () => {
    if (oldPassword.trim() === "") {
      setOldPasswordError("Please enter your old password. *");
      toast.error("Please enter your old password.");
      return;
    }
    if (!validatePassword(newPassword)) {
      toast.error(
        "Password must be 8-20 characters, including at least one lowercase letter, one uppercase letter, one digit, and one special character."
      );
      return;
    }
    if (confirmNewPassword.trim() === "") {
      setConfirmPasswordError("Please confirm your new password. *");
      toast.error("Please confirm your new password.");
      return;
    }
    if (newPassword !== confirmNewPassword) {
      setConfirmPasswordError("Passwords do not match. *");
      toast.error("Passwords do not match.");
      return;
    }

    // If all validations pass

    // Create a new FormData object
    const formData = new FormData();

    formData.append("user_id", userID);
    formData.append("old_pass", oldPassword);
    formData.append("New_pass", newPassword);
    formData.append("conf_pas", confirmNewPassword);

    // API call to update !
    try {
      setLoading(true);

      const response = await updateOldToNewPasswordAPI(formData);

      setLoading(false);

      if (response?.data?.response?.response === true) {
        // Reset errors
        setOldPasswordError("");
        setPasswordError("");
        setConfirmPasswordError("");

        // Show success toast message
        toast.success("Password updated successfully.");

        // Reset fields
        setOldPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
        handleClose();
      } else {
        // If API call fails, display error message
        toast.error(
          response?.data?.response?.error_msg ||
            "Failed to update !"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error saupdate !:", error);
      toast.error("Failed to update !");
    }
  };

  const handleCloseModal = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
    setOldPasswordError("");
    setPasswordError("");
    setConfirmPasswordError("");

    handleClose();
  };

  const togglePasswordVisibility = (passwordType) => {
    switch (passwordType) {
      case "old":
        setShowOldPassword(!showOldPassword);
        break;
      case "new":
        setShowNewPassword(!showNewPassword);
        break;
      case "confirm":
        setShowConfirmNewPassword(!showConfirmNewPassword);
        break;
      default:
        break;
    }
  };

  return (
    <Modal show={show} onHide={handleCloseModal} centered>
      <Modal.Header closeButton>
        <Modal.Title
          className="text-center"
          style={{
            fontSize: "18px",
            fontFamily: "Arial, sans-serif",
            color: "#055837",
          }}
        >
          Password Reset
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formOldPassword" className="mb-3">
            <Form.Label className="w-100 mb-1">
              Old Password <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <div className="input-group">
              <Form.Control
                type={showOldPassword ? "text" : "password"}
                placeholder="Enter old password"
                value={oldPassword}
                onChange={(e) => {
                  setOldPassword(e.target.value);
                  setOldPasswordError("");
                }}
              />
              <Button
                variant="outline-secondary"
                type="button"
                onClick={() => togglePasswordVisibility("old")}
              >
                {showOldPassword ? (
                  <FaEyeSlash size={20} />
                ) : (
                  <FaEye size={20} />
                )}
              </Button>
            </div>
            {oldPasswordError && (
              <Form.Text className="text-danger">{oldPasswordError}</Form.Text>
            )}
          </Form.Group>

          <Form.Group controlId="formNewPassword" className="mb-3">
            <Form.Label className="w-100 mb-1">
              New Password <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <div className="input-group">
              <Form.Control
                type={showNewPassword ? "text" : "password"}
                placeholder="Enter new password"
                value={newPassword}
                onChange={(e) => {
                  const newPasswordValue = e.target.value;
                  setNewPassword(newPasswordValue);
                  // Validate new password
                  if (!validatePassword(newPasswordValue)) {
                    setPasswordError(
                      "Password must be 8-20 characters, including at least one lowercase letter, one uppercase letter, one digit, and one special character."
                    );
                  } else {
                    setPasswordError("");
                  }
                }}
              />
              <Button
                variant="outline-secondary"
                type="button"
                onClick={() => togglePasswordVisibility("new")}
              >
                {showNewPassword ? (
                  <FaEyeSlash size={20} />
                ) : (
                  <FaEye size={20} />
                )}
              </Button>
            </div>
            {passwordError && (
              <Form.Text className="text-danger">{passwordError}</Form.Text>
            )}
          </Form.Group>

          <Form.Group controlId="formConfirmNewPassword" className="mb-3">
            <Form.Label className="w-100 mb-1">
              Confirm New Password <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <div className="input-group">
              <Form.Control
                type={showConfirmNewPassword ? "text" : "password"}
                placeholder="Confirm new password"
                value={confirmNewPassword}
                onChange={(e) => {
                  const confirmNewPasswordValue = e.target.value;
                  setConfirmNewPassword(confirmNewPasswordValue);
                  // Validate confirm new password
                  if (confirmNewPasswordValue !== newPassword) {
                    setConfirmPasswordError("Passwords do not match. *");
                  } else {
                    setConfirmPasswordError("");
                  }
                }}
              />
              <Button
                variant="outline-secondary"
                type="button"
                onClick={() => togglePasswordVisibility("confirm")}
              >
                {showConfirmNewPassword ? (
                  <FaEyeSlash size={20} />
                ) : (
                  <FaEye size={20} />
                )}
              </Button>
            </div>
            {confirmPasswordError && (
              <Form.Text className="text-danger">
                {confirmPasswordError}
              </Form.Text>
            )}
          </Form.Group>
        </Form>
      </Modal.Body>
{/* 
      {loading && (
        <div className="d-flex justify-content-center mt-3">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )} */}

{loading && (
 <Loader />
      )}


      <Modal.Footer>
        <Button
          variant="light"
          onClick={handleSubmitUpdatePassword}
          style={{
            width: "200px",
            backgroundColor: "#055837",
            color: "#ffcc01",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "5px",
            border: "none",
            borderRadius: "5px",
            transition: "background-color 0.3s, color 0.3s",
            margin: "0 auto",
          }}
          onMouseEnter={(e) => {
            e.target.style.backgroundColor = "#ffcc01";
            e.target.style.color = "#055837";
          }}
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = "#055837";
            e.target.style.color = "#ffcc01";
          }}
        >
          Update Password
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdatePasswordModal;
