import React, { useState, useEffect } from "react";
import { Row, Col, Dropdown } from "react-bootstrap";
import {
  BsList,
  BsPencilSquare,
  BsFileText,
  BsExclamationTriangle,
  BsFillPersonFill,
  BsBoxArrowRight,
} from "react-icons/bs";
import { FaChartPie, FaHome } from "react-icons/fa";
import "./Navigation Buttons.css";
import { useNavigate, useLocation } from "react-router-dom";
import { getUserDataAPI } from "../../api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NavigationButtons = ({ handleUpdatePersonalDetails, handleLogout }) => {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current path

  const [userProfileData, setUserProfileData] = useState({});
  const [showEmploymentVerification, setShowEmploymentVerification] =
    useState(false);
  const [showCreateNewApplicationTab, setShowCreateNewApplicationTab] =
    useState(false);
  const [showIncompleteApplicationTabTab, setShowIncompleteApplicationTabTab] =
    useState(false);

  // Mapping paths to icons and labels
  const pathInfo = {
    "/user/dashboard": {
      icon: <FaHome />,
      label: "Dashboard",
      action: () => navigate("/user/dashboard"),
    },
    "/user/incomplete_applications": {
      icon: <BsPencilSquare />,
      label: "Incomplete Applications",
      action: () => navigate("/user/incomplete_applications"),
    },
    "/user/submitted_applications": {
      icon: <BsFileText />,
      label: "Submitted Applications",
      action: () => navigate("/user/submitted_applications"),
    },
    "/user/employment_verification_pending": {
      icon: <BsExclamationTriangle />,
      label: "Employment Verification Pending",
      action: () => navigate("/user/employment_verification_pending"),
    },
  };

  const defaultIcon = <BsList size={18} style={{ color: "#055837" }} />;
  const defaultLabel = "Menu";

  const currentPath = location.pathname;
  const { icon: currentIcon, label: currentLabel } = pathInfo[currentPath] || {
    icon: defaultIcon,
    label: defaultLabel,
  };

  const userID = localStorage.getItem("userId");


  const userName = localStorage.getItem("userName");




  const fetchUserData = async () => {
    try {
      const response = await getUserDataAPI(userID);

      if (
        response.data?.response &&
        response.data?.response?.response === true &&
        response.data?.response?.user_details
      ) {
        setUserProfileData(response?.data?.response?.user_details);
      } else if (
        response.status === 200 &&
        response.data === "Missing JWT token"
      ) {
        fetchUserData(userID);
      } else {
        console.error(
          "Error fetching:",
          response.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching:", error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    setShowEmploymentVerification(
      localStorage.getItem("showEmploymentVerification")
    );
    setShowCreateNewApplicationTab(
      localStorage.getItem("showCreateNewApplicationTab")
    );
    setShowIncompleteApplicationTabTab(
      localStorage.getItem("showIncompleteApplicationTabTab")
    );
  }, []);

  // Filter menu options based on the current path to avoid showing the same option
  const menuOptions = Object.keys(pathInfo).filter(
    (path) => path !== currentPath
  );

  return (
    <Row className="mb-0">
      <Col
        className="d-flex justify-content-end align-items-center"
        style={{ marginRight: "25px", color: "#055837" }}
      >
        <Dropdown className="me-2">
          <Dropdown.Toggle
            variant=""
            id="dashboard-dropdown"
            className="border-0 text-dark d-flex align-items-center"
            style={{ color: "#055837", outline: "none", boxShadow: "none" }}
          >
            <div
              className="me-1"
              style={{ display: "inline-flex", alignItems: "center" }}
            >
              {currentIcon}
              <span
                style={{
                  color: "#055837",
                  verticalAlign: "middle",
                  marginLeft: "8px",
                }}
                onMouseEnter={(e) =>
                  (e.target.style.textDecoration = "underline")
                }
                onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
              >
                {currentLabel}
              </span>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {menuOptions.map((path) => (
              <Dropdown.Item
                key={path}
                onClick={pathInfo[path].action}
                className="d-flex align-items-center"
              >
                <span className="me-2 d-flex align-items-center">
                  {pathInfo[path].icon}
                </span>
                <span>{pathInfo[path].label}</span>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown>
          <Dropdown.Toggle
            variant=""
            id="user-dropdown"
            className="border-0 text-dark d-flex align-items-center"
            style={{ color: "#055837", outline: "none", boxShadow: "none" }}
          >
            <BsFillPersonFill className="me-1" style={{ color: "#055837" }} />
            <span
              style={{
                color: "#055837",
                textDecoration: "none",
                transition: "text-decoration 0.2s ease",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.textDecoration = "underline";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.textDecoration = "none";
              }}
            >
              Welcome{" "}
              <b style={{ textDecoration: "inherit" }}>
                {/* {userProfileData?.name} */}
                {userName}
              </b>
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={handleUpdatePersonalDetails}>
              <BsPencilSquare className="me-2" /> My Profile
            </Dropdown.Item>
            <Dropdown.Item onClick={handleLogout}>
              <BsBoxArrowRight className="me-2" /> Sign Out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Col>
    </Row>
  );
};

export default NavigationButtons;
