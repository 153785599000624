import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import ScrollToTop from "./ScrollToTop";

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import ForgotPassword from "./components/Forgot Password/Forgot Password";
import CreateNewAccount from "./components/Create New Account/Create New Account";
import Dashboard from "./components/Dashboard/Dashboard";
import UpdateProfile from "./components/Update Profile/Update Profile";
import Login from "./components/Login/Login";
import CreateNewApplication from "./components/Create New Application/Create New Application";
import OccupationDetails from "./components/Create New Application/stage_1/Occupation Details/Occupation Details";
import PersonalDetails from "./components/Create New Application/stage_1/Personal Details/Personal Details";
import ContactDetails from "./components/Create New Application/stage_1/Contact Details/Contact Details";
import Identification from "./components/Create New Application/stage_1/Identification/Identification";
import USIAndAvetmiss from "./components/Create New Application/stage_1/USI & Avetmiss/USI & Avetmiss";
import EducationAndEmployment from "./components/Create New Application/stage_1/Education & Employment/Education & Employment";
import ReviewAndConfirm from "./components/Create New Application/stage_1/Review & Confirm/Review & Confirm";
import ApplicantDeclaration from "./components/Create New Application/stage_1/Applicant Declaration/Applicant Declaration";
import UploadDocuments from "./components/Create New Application/stage_1/Upload Documents/Upload Documents";
import IncompleteApplications from "./components/Incomplete Applications/Incomplete Applications";
import SubmittedApplications from "./components/Submitted Applications/Submitted Applications";
import EmploymentVerificationPending from "./components/Employment Verification Pending/Employment Verification Pending";
import Forms from "./components/Forms/Forms";
import CompleteProfile from "./components/Complete Profile/Complete Profile";
import ResetPassworddd from "./components/Reset Password/Reset Password";
import ViewApplication from "./components/Create New Application/View Application/View Application";
import DocumentaryEvidence from "./components/Create New Application/stage_2/Documentary Evidence";
import DocumentaryEvidenceUploadDocs from "./components/Create New Application/stage_2/Documentary Evidence Upload Docs/Documentary Evidence Upload Docs";
import PracticalAssessment from "./components/Create New Application/stage_4/Practical Assessment";
import InternetChecker from "./components/Internet Checker/Internet Checker";
import OffShoreTechnicalInterview from "./components/Create New Application/stage_3/Off Shore/OffShore Technical Interview";
import OnShoreTechnicalInterview from "./components/Create New Application/stage_3/On Shore/OnShore Technical Interview";
import OffShoreTechnicalInterview_R from "./components/Create New Application/stage_3_R/Off Shore/OffShore Technical Interview";
import OnShoreTechnicalInterview_R from "./components/Create New Application/stage_3_R/On Shore/OnShore Technical Interview";
import ViewEmploymentVerificationPending from "./components/Employment Verification Pending/View Employment Verification Pending";
import FinalPage from "./components/Create New Application/Final Page/Final Page";
import { ClockFill } from "react-bootstrap-icons";
import Stage_2_R_Submit_Page from "./components/Create New Application/stage_2_R/Stage_2_R_Submit_Page";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [ProfileDetailsFilled, setProfileDetailsFilled] = useState(false);

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    const encryptedToken = localStorage.getItem("encryptedToken");

    setProfileDetailsFilled(localStorage.getItem("profileDetailsFilled"));

    if (isLoggedIn === "true" && encryptedToken) {
      setLoggedIn(true);
    }
  }, []);


  useEffect(() => {
    const intervalId = setInterval(() => {
      const isLoggedIn = localStorage.getItem("isLoggedIn");
      const encryptedToken = localStorage.getItem("encryptedToken");
  
      setProfileDetailsFilled(localStorage.getItem("profileDetailsFilled"));
  
      if (isLoggedIn === "true" && encryptedToken) {
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
    }, 1000); // This runs every 1 second
  
    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  


  useEffect(() => {
    const updateProfileDetailsFilled = () => {
      setProfileDetailsFilled(localStorage.getItem("profileDetailsFilled"));
    };

    updateProfileDetailsFilled();

    window.addEventListener("storage", updateProfileDetailsFilled);

    return () => {
      window.removeEventListener("storage", updateProfileDetailsFilled);
    };
  }, []);

  const handleLogin = () => {
    setLoggedIn(true);
  };

  const handleLogout = () => {
    setLoggedIn(false);
    localStorage.clear();
  };

  const [isOffline, setIsOffline] = useState(false);

  useEffect(() => {
    const handleOffline = () => setIsOffline(true);
    const handleOnline = () => setIsOffline(false);

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, []);

  const PrivateRoute = ({ element: Element, loggedIn, onLogout }) => {
    return loggedIn ? <Element onLogout={onLogout} /> : "";
  };

  return (
    <>
      <div className="App">
        <BrowserRouter>
          <ScrollToTop />

          {isOffline && <InternetChecker />}

          <Header />

          <Routes>
            {loggedIn && (
              <>
                {ProfileDetailsFilled === "true" ? (
                  <>
                    <Route
                      path="/"
                      element={<Navigate to="/user/dashboard" />}
                    />
                    <Route
                      path="/user/forgot_password"
                      element={<Navigate to="/user/dashboard" />}
                    />
                    <Route
                      path="/user/create_new_account"
                      element={<Navigate to="/user/dashboard" />}
                    />
                    <Route
                      path="/user/complete_profile"
                      element={<Navigate to="/user/dashboard" />}
                    />
                    <Route
                      path="/user/reset_password"
                      element={<Navigate to="/user/dashboard" />}
                    />
                  </>
                ) : (
                  <>
                    <Route
                      path="/"
                      element={<Navigate to="/user/complete_profile" />}
                    />
                  </>
                )}
              </>
            )}

            <Route
              path="/user/dashboard"
              element={
                <PrivateRoute
                  element={Dashboard}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                />
              }
            />
            <Route
              path="/user/update_profile"
              element={
                <PrivateRoute
                  element={UpdateProfile}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                />
              }
            />
            <Route
              path="/user/incomplete_applications"
              element={
                <PrivateRoute
                  element={IncompleteApplications}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                />
              }
            />
            <Route
              path="/user/submitted_applications"
              element={
                <PrivateRoute
                  element={SubmittedApplications}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                />
              }
            />
            <Route
              path="/user/employment_verification_pending"
              element={
                <PrivateRoute
                  element={EmploymentVerificationPending}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                />
              }
            />
            <Route
              path="/user/employment_verification_pending_details"
              element={
                <PrivateRoute
                  element={ViewEmploymentVerificationPending}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                />
              }
            />
            <Route
              path="/user/forms"
              element={
                <PrivateRoute
                  element={Forms}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                />
              }
            />
            <Route
              path="/user/view_application"
              element={
                <PrivateRoute
                  element={ViewApplication}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                />
              }
            />
            <Route
              path="/user/final_page"
              element={
                <PrivateRoute
                  element={FinalPage}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                />
              }
            />
            <Route
              path="/user/stage_2/documentary_evidence"
              element={
                <PrivateRoute
                  element={DocumentaryEvidence}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                />
              }
            />
            <Route
              path="/user/stage_2/documentary_evidence/upload_documents"
              element={
                <PrivateRoute
                  element={DocumentaryEvidenceUploadDocs}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                />
              }
            />
            <Route
              path="/user/stage_2_R/documentary_evidence"
              element={
                <PrivateRoute
                  element={Stage_2_R_Submit_Page}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                />
              }
            />
            <Route
              path="/user/stage_3/offshore_technical_interview"
              element={
                <PrivateRoute
                  element={OffShoreTechnicalInterview}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                />
              }
            />
            <Route
              path="/user/stage_3/onshore_technical_interview"
              element={
                <PrivateRoute
                  element={OnShoreTechnicalInterview}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                />
              }
            />
            <Route
              path="/user/stage_3_R/offshore_technical_interview"
              element={
                <PrivateRoute
                  element={OffShoreTechnicalInterview_R}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                />
              }
            />
            <Route
              path="/user/stage_3_R/onshore_technical_interview"
              element={
                <PrivateRoute
                  element={OnShoreTechnicalInterview_R}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                />
              }
            />
            <Route
              path="/user/stage_4/practical_assessment"
              element={
                <PrivateRoute
                  element={PracticalAssessment}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                />
              }
            />

            <Route
              path="/user/create_new_application"
              element={
                <PrivateRoute
                  element={CreateNewApplication}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                />
              }
            >
              <Route
                path="stage_1/occupation_details"
                element={<OccupationDetails />}
              />
              <Route
                path="stage_1/personal_details"
                element={<PersonalDetails />}
              />
              <Route
                path="stage_1/contact_details"
                element={<ContactDetails />}
              />
              <Route
                path="stage_1/identification"
                element={<Identification />}
              />
              <Route
                path="stage_1/usi_and_avetmiss"
                element={<USIAndAvetmiss />}
              />
              <Route
                path="stage_1/education_employment"
                element={<EducationAndEmployment />}
              />
              <Route
                path="stage_1/review_and_confirm"
                element={<ReviewAndConfirm />}
              />
              <Route
                path="stage_1/application_declaration"
                element={<ApplicantDeclaration />}
              />
              <Route
                path="stage_1/upload_documents"
                element={<UploadDocuments />}
              />
            </Route>

            <Route
              path="/"
              element={<Login onLogin={handleLogin} onLogout={handleLogout} />}
            />
            <Route path="/user/forgot_password" element={<ForgotPassword />} />
            <Route
              path="/user/create_new_account"
              element={<CreateNewAccount />}
            />
            <Route
              path="/user/complete_profile"
              element={<CompleteProfile onLogout={handleLogout} />}
            />
            <Route path="/user/reset_password" element={<ResetPassworddd />} />

            {/* Catch-all route */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
