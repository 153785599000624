import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import "./Others.css";
import ConfirmationModal from "../../../../Confirmation Modal/ConfirmationModal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  uploaadDocuments_stage1_API,
  deleteDocument_stage1_API,
  getDocumentsDetails_stage1_API,
} from "../../../../../api";
import Loader from "../../../../Loader/Loader";

const Others = ({
  show,
  onHide,
  pointerData,
  getUploadedDocsList,
  ModalHeading,
  ModalID,
  allowedTypes,
  is_required,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [cancelClicked, setCancelClicked] = useState(false);
  const [qualificationName, setQualificationName] = useState("");

  const [inputKey, setInputKey] = useState(Date.now());

  const clearFileInput = () => {
    setSelectedFile(null);
    setInputKey(Date.now()); // Change key to reset the input field
  };

  const [loading, setLoading] = useState(false);

  const [Upload_File_Loading, setUpload_File_Loading] = useState(false);

  const required_document_id = ModalID;

  const [UpladedDocsList, setUpladedDocsList] = useState([]);

  const [uploadedDocumentSelected, setUploadedDocumentSelected] =
    useState(null);

  const getDocumentDetailsFunction = async () => {
    try {
      setUpload_File_Loading(true);
      const response = await getDocumentsDetails_stage1_API(
        pointerData?.pointer_id,
        required_document_id
      );
      setUpload_File_Loading(false);

      if (
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        const uploadedFileDetails = response?.data?.response?.data;

        setUpladedDocsList(uploadedFileDetails);

        getUploadedDocsList();
      } else {
        setUpladedDocsList([]);
        console.error(
          "Error fetching document details:",
          response?.data?.response?.error_msg || "Unknown error"
        );

        getUploadedDocsList();
      }
    } catch (error) {
      setUpload_File_Loading(false);
      console.error("Error fetching document details:", error);

      getUploadedDocsList();
    }
  };
  useEffect(() => {
    if (show) {
      getDocumentDetailsFunction();
    }
  }, [show]);

  // Function to handle file selection
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.name.split(".").pop(); // Get the file extension

      const maxSizeInBytes = 100 * 1024 * 1024; // 100MB in bytes

      // Check if the selected file exceeds the size limit
      if (file.size > maxSizeInBytes) {
        toast.error("File size should not exceed 100MB.");
        event.target.value = ""; // Clear the input field to prevent file selection
        return;
      }

      if (allowedTypes.includes(fileType)) {
        setSelectedFile(file);
      } else {
        // Show toast notification if the file type is not allowed
        toast.error(`Please select a file of type: ${allowedTypes.join(", ")}`);
        event.target.value = ""; // Clear the input field
        setSelectedFile(null); // Reset the state
      }
    }
  };

  const [uploadProgress, setUploadProgress] = useState(0);

  // Function to handle uploading the image
  const handleUpload = async () => {
    if (qualificationName.trim() === "") {
      toast.error("Please enter document name.");
      document.getElementById(`qualificationNameInput`).focus();
      return;
    }

    if (!selectedFile) {
      toast.error("Please select the file.");
      return;
    }

    // Construct form data object
    const formData = new FormData();
    formData.append("pointer_id", pointerData?.pointer_id);
    formData.append("file", selectedFile);
    formData.append("name", qualificationName);
    formData.append("required_document_id", required_document_id);
    formData.append("stage", "stage_1");
    formData.append("status", "1");

    try {
      setUpload_File_Loading(true);

      const response = await uploaadDocuments_stage1_API(
        formData,
        pointerData?.pointer_id
      );

      setUpload_File_Loading(false);

      if (response?.data?.response?.response === true) {
        getDocumentDetailsFunction();
        setSelectedFile(null);
        setQualificationName("");
        setUploadedDocumentSelected(null);
        getUploadedDocsList();
        toast.success("File Uploaded Successfully");
        clearFileInput();
      } else {
        // If API call fails, display error message
        toast.error(
          response?.data?.response?.error_msg || "Failed to upload file."
        );
      }
    } catch (error) {
      setUpload_File_Loading(false);

      console.error("Failed to upload file.", error);
      toast.error("Failed to upload file.");
    }
  };

  const handleDeleteFile = async () => {
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("pointer_id", pointerData?.pointer_id);
      formData.append("document_id", uploadedDocumentSelected?.id);

      const response = await deleteDocument_stage1_API(formData);

      setLoading(false);

      if (response?.data?.response?.response === true) {
        toast.success("Document deleted successfully.");
        setCancelClicked(false);
        setCancelClicked(false);
        setSelectedFile(null);
        setQualificationName("");
        setUploadedDocumentSelected(null);
        getUploadedDocsList();
        clearFileInput();
        getDocumentDetailsFunction();
      } else {
        // If API call fails, display error message
        toast.error(
          response?.data?.response?.error_msg || "Failed to delete file."
        );
        setCancelClicked(false);
      }
    } catch (error) {
      setLoading(false);
      setCancelClicked(false);

      console.error("Failed to delete file.", error);
      toast.error("Failed to delete file.");
    }
  };

  return (
    <>
      {loading && <Loader />}

      <Modal
        show={show}
        onHide={onHide}
        centered
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="text-center"
            style={{
              fontSize: "18px",
              fontFamily: "Arial, sans-serif",
              color: "#055837",
            }}
          >
            {/* Upload  */}
            {ModalHeading}{" "}
            {is_required === "1" && <span style={{ color: "red" }}>*</span>}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-6">
              <div className="row mb-3 mt-2 bg-offwhite">
                <div className="col-md-5 d-flex align-items-center justify-content-start">
                  <Form.Group controlId={`qualificationNameLabel`}>
                    <Form.Label
                      htmlFor={`qualificationNameInput`}
                      style={{
                        textAlign: "left",
                        paddingLeft: "10px",
                        fontSize: "15px",
                      }}
                    >
                      ▸ Document Name <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                  </Form.Group>
                </div>
                <div className="col-md-7">
                  <Form.Control
                    type="text"
                    id={`qualificationNameInput`}
                    placeholder="Enter your document name"
                    value={qualificationName}
                    onChange={(e) => setQualificationName(e.target.value)}
                  />
                </div>
              </div>

              <div>
                <Form.Group controlId="formFile" className="mb-2 mt-1">
                  <Form.Label>▸ Select a file:</Form.Label>
                  <Form.Control
                    key={inputKey}
                    type="file"
                    onChange={handleFileSelect}
                    accept={allowedTypes.map((type) => `.${type}`).join(",")}
                    style={{ height: "35px" }}
                  />
                  <div className="text-danger text-size-control-extensions">
                    Only:{" "}
                    {allowedTypes.map((type, index) => (
                      <React.Fragment key={index}>
                        .{type}
                        {index !== allowedTypes.length - 1 && ", "}
                      </React.Fragment>
                    ))}
                  </div>
                </Form.Group>
              </div>
            </div>

            <div className="col-6 mt-1 d-flex align-items-center justify-content-center text-size-control">
              <div
                style={{
                  width: "100%", // Use full width of the container
                  border: "1px solid #ccc", // Add border
                  borderRadius: "5px", // Optional: add some rounding to the corners
                  padding: "10px", // Add padding for better spacing
                  height: "250px", // Fixed height for the div
                  overflowY: "auto", // Make it scrollable if content overflows
                }}
              >
               <table className="table">
  <thead>
    <tr>
      <th scope="col" style={{ width: "10%" }}>#</th>
      <th scope="col" style={{ width: "80%" }}>Documents</th>
      <th scope="col" style={{ width: "10%" }}>Action</th>
    </tr>
  </thead>
  <tbody>
    {UpladedDocsList.length > 0 ? (
      UpladedDocsList.map((file, index) => (
        <tr key={file.id}>
          <td>{index + 1}.</td> {/* Serial Number */}
          <td>
            <div>
              <a
                href={file.file_url}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "#007bff",
                  textDecoration: "none",
                  transition: "color 0.2s",
                }}
                onMouseEnter={(e) => (e.currentTarget.style.color = "#0056b3")}
                onMouseLeave={(e) => (e.currentTarget.style.color = "#007bff")}
              >
                {file.name}
              </a>
            </div>
          </td>
          <td>
            <i
              className="fas fa-trash-alt"
              onClick={() => {
                setUploadedDocumentSelected(file);
                setCancelClicked(true);
              }}
              style={{
                color: "red",
                cursor: "pointer",
                transition: "transform 0.0s",
              }}
              onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.2)")}
              onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
            ></i>
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan="3" className="text-center" style={{ color: "#999" }}>
          No files uploaded.
        </td>
      </tr>
    )}


   {/* <tr>
                      <td colSpan="3" style={{ padding: "0" }}>
                        <div
                          style={{
                            width: "100%",
                            backgroundColor: "#f3f3f3",
                            borderRadius: "50px",
                            overflow: "hidden",
                          }}
                        >
                          <div
                            style={{
                              width: `${uploadProgress}%`,
                              height: "20px",
                              backgroundColor: "#055837", // Green color for the uploaded part
                              borderRadius: "50px", // Making the uploaded part cylindrical as well
                              textAlign: "center",
                              lineHeight: "20px",
                              color: "#ffcc01", // Yellow color for the percentage text
                              fontWeight: "bold", // Bold text for better visibility
                            }}
                          >
                            {uploadProgress}%
                          </div>
                        </div>
                      </td>
                    </tr> */}





    {/* Spinner Row */}
    {Upload_File_Loading && (
      <tr>
        <td colSpan="3" style={{ textAlign: "center", padding: "20px 0" }}>
          <Spinner
            animation="border"
            role="status"
            style={{
              height: "35px",
              width: "35px",
              color: "#055837",
            }}
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </td>
      </tr>
    )}







  </tbody>
</table>

              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-center">
          <Button
            variant="light"
            onClick={onHide}
            className="w-50"
            style={{
              maxWidth: "130px",
              backgroundColor: "#dc3545",
              color: "white",
              transition: "transform 0.1s",
            }}
            onMouseEnter={(e) => (e.target.style.transform = "scale(1.02)")}
            onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
          >
            Close
          </Button>

          <Button
            variant="light"
            onClick={handleUpload}
            className="w-50"
            style={{
              maxWidth: "130px",
              backgroundColor: "#055837",
              color: "#ffcc01",
              transition: "transform 0.1s",
            }}
            onMouseEnter={(e) => (e.target.style.transform = "scale(1.02)")}
            onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
          >
            Upload <i className="bi bi-upload"></i>
          </Button>
        </Modal.Footer>

        {cancelClicked && uploadedDocumentSelected && (
          <ConfirmationModal
            show={cancelClicked}
            onHide={() => {
              setUploadedDocumentSelected(null);
              setCancelClicked(false);
            }}
            onConfirm={handleDeleteFile}
            title="Confirm Delete"
            message="Are you sure you want to remove this file?"
          />
        )}
      </Modal>
    </>
  );
};

export default Others;
