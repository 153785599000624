import React, { useState, useEffect } from "react";
import { Card, Form, Spinner } from "react-bootstrap";
import "./Applicant Declaration.css";
import ConfirmationModal from "../../../Confirmation Modal/ConfirmationModal";
import { json, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getApplicantDeclaration_stage1_API,
  postApplicationDeclaration_CheckPDFDownloaded_stage1_Application_PDF_API,
  post_CheckPDFDownloaded_ApplicationDeclaration_stage1_Application_PDF_API,
} from "../../../../api";
import { useLocation } from "react-router-dom";
import Loader from "../../../Loader/Loader";

const ApplicantDeclaration = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pointerData = location.state?.pointerData;

  const [loading, setLoading] = useState(false);

  const [NextBTN_DisableStatus, setNextBTN_DisableStatus] = useState(true);



  const [files, setFiles] = useState([]);
  const [clickedStatus, setClickedStatus] = useState({});
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const fetchApplicantDeclarationDataPDF = async () => {
      try {
        setLoading(true);
        const response = await getApplicantDeclaration_stage1_API(
          pointerData?.pointer_id
        );
        setLoading(false);

        if (
          response.data?.response?.response === true &&
          response.data?.response?.data
        ) {
          const filesData = response?.data?.response?.data?.files;
          setFiles(filesData);
          setNextBTN_DisableStatus(response?.data?.response?.data?.next_btn);
          // Initialize clicked status for each file
          const initialStatus = filesData.reduce((acc, file) => {
            acc[file.name] = false;
            return acc;
          }, {});

          setClickedStatus(initialStatus);
        } else {
          console.error(
            "Error fetching data:",
            response.data?.response?.error_msg || "Unknown error"
          );
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    };

    useEffect(() => {
    if (pointerData) {
      fetchApplicantDeclarationDataPDF();
    }
  }, [pointerData]);

  const handleFileClick = async (fileName, fileUrl, fileParameter) => {
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("pointer_id", pointerData?.pointer_id);
      formData.append("parameter", fileParameter);

      const response =
        await postApplicationDeclaration_CheckPDFDownloaded_stage1_Application_PDF_API(
          formData
        );

      setLoading(false);

      if (
        response?.data &&
        response.status === 200 &&
        response?.data?.response &&
        response?.data?.response?.response === true
      ) {
        const downloadLink = document.createElement("a");
        downloadLink.href = fileUrl;
        downloadLink.setAttribute("download", `${fileName}.pdf`);
        downloadLink.setAttribute("target", "_blank");
        downloadLink.click();
        fetchApplicantDeclarationDataPDF();
        toast.success(`Please download the opened ${fileName}.`);
        setClickedStatus((prevStatus) => ({ ...prevStatus, [fileName]: true }));
      } else {
        console.error(
          "Error fetching Application Preview pdf file:",
          response.data?.error_msg || "Unknown error"
        );

        toast.error(response.data?.error_msg);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const handleNext = async () => {
    const passing_parameters = getProperFormatParameters(files);

    // const allFilesParams = files.every((file) => [file?.parameter]);

    try {
      const formData = new FormData();
      formData.append("pointer_id", pointerData?.pointer_id);
      formData.append("parameters", JSON.stringify(passing_parameters));

      setLoading(true);

      const response =
        await post_CheckPDFDownloaded_ApplicationDeclaration_stage1_Application_PDF_API(
          formData
        );
      setLoading(false);

      if (
        response?.data &&
        response.status === 200 &&
        response?.data?.response &&
        response?.data?.response?.response === true
      ) {
        navigate("/user/create_new_application/stage_1/upload_documents", {
          state: { pointerData },
        });
        toast.success("Applicant Declaration done successfully.");
      } else {
        toast.error(
          response?.data?.response?.error_msg || "Error in downloading files:"
        );
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error in fetching downloaded files status.");
    }
  };

  const getProperFormatParameters = (files) => {
    var newFiles = [];
    files.forEach((file) => {
      newFiles.push(file.parameter);
    });
    return newFiles;
  };

  const handleSaveAndExit = () => {
    // const allFilesClicked = files.every((file) => clickedStatus[file.name]);

    // if (!allFilesClicked) {
    //   toast.error("Please download all forms before proceeding.");
    //   return;
    // }

    setShowConfirmationModal(true);
  };

  const handleConfirmationNo = () => {
    setShowConfirmationModal(false);
  };

  const handleConfirmationYes = () => {
    navigate("/user/dashboard");
    setShowConfirmationModal(false);
    toast.success("Applicant Declaration saved successfully.");
  };

  const handleBack = () => {
    navigate("/user/create_new_application/stage_1/review_and_confirm", {
      state: { pointerData },
    });
    toast.success("Navigated to Review & Confirm form page.");
  };

  return (
    <>
      <div className="forgot-container-new-USI">
        <Card className="shadow forgot-card-new-USI">
          <div
            className="card-header text-center"
            style={{ fontSize: "20px", color: "#055837" }}
          >
            <b>Applicant Declaration</b>
          </div>
          <Card.Body>
            <div className="row">
              <div className="col-md-7"></div>
              <div className="col-md-5 d-flex justify-content-end">
                <div className="mb-3 mt-1">
                  Portal Reference No. :{" "}
                  <b style={{ color: "#055837" }}>
                    {pointerData?.portal_refrance_no}
                  </b>
                </div>
              </div>
            </div>

            <Form>
              <div className="col-lg-12 bg-warning rounded p-3 text-center">
                <span className="m-3">
                  PLEASE DOWNLOAD ALL THE BELOW FORMS FOR SIGNATURES BEFORE YOU
                  PROCEED TO THE NEXT STEP.
                </span>
              </div>

              {/* {loading && (
                <div className="d-flex justify-content-center mt-3">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              )} */}

              {loading && <Loader />}

              <div>
                <table className="tableCustom table-hover">
                  <tbody>
                    {files.map((file) => (
                      <tr key={file?.name}>
                        <td>
                          <div>
                            <a
                              href="javascript:void(0)"
                              onClick={() =>
                                handleFileClick(
                                  file?.name,
                                  file?.url,
                                  file?.parameter
                                )
                              }
                              className="text-decoration-none d-flex align-items-center"
                              style={{ marginBottom: "10px" }}
                            >
                              {file?.extension === "pdf" ? (
                                <i className="bi bi-file-earmark-pdf-fill text-danger me-2"></i>
                              ) : (
                                <i className="bi bi-file-earmark-word-fill text-primary me-2"></i>
                              )}
                              <span style={{ marginRight: "15px" }}>
                                {file?.name}
                              </span>
                              <i className="bi bi-download me-2"></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div
                className="button-group text-center mb-1"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{
                    backgroundColor: "#ffcc01",
                    color: "#055837",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    border: "none",
                    borderRadius: "5px",
                    marginTop: "30px",
                    marginBottom: "20px",
                    transition: "background-color 0.3s, color 0.3s",
                  }}
                  onClick={handleBack}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = "#055837";
                    e.target.style.color = "#ffcc01";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "#ffcc01";
                    e.target.style.color = "#055837";
                  }}
                >
                  Back
                </button>

                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSaveAndExit}
                  style={{
                    backgroundColor: "#055837",
                    color: "#ffcc01",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    border: "none",
                    borderRadius: "5px",
                    marginTop: "30px",
                    marginBottom: "20px",
                    transition: "background-color 0.3s, color 0.3s",
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = "#ffcc01";
                    e.target.style.color = "#055837";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "#055837";
                    e.target.style.color = "#ffcc01";
                  }}
                >
                  Save & Exit
                </button>

                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={!NextBTN_DisableStatus}
                  style={{
                    backgroundColor: "#ffcc01",
                    color: "#055837",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    border: "none",
                    borderRadius: "5px",
                    marginTop: "30px",
                    marginBottom: "20px",
                    transition: "background-color 0.3s, color 0.3s",
                  }}
                  onClick={handleNext}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = "#055837";
                    e.target.style.color = "#ffcc01";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "#ffcc01";
                    e.target.style.color = "#055837";
                  }}
                >
                  Next
                </button>
              </div>
            </Form>
          </Card.Body>

          <ConfirmationModal
            show={showConfirmationModal}
            onHide={handleConfirmationNo}
            onConfirm={handleConfirmationYes}
            title="Confirm Leaving"
            message="Are you sure you want to leave this page? You don't want to continue this new application process right now? If you proceed, you'll be redirected to the dashboard."
          />
        </Card>
      </div>
    </>
  );
};

export default ApplicantDeclaration;
